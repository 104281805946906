<template>
  <div class="article">
    <h1>多点 - 活动管理系统</h1>
    <i>一款快速创建、上线电商活动的管理系统</i>
      <p>所属公司：<a href="https://dmall.com/" target="_blank">Dmall 多点</a> </p>
      <p>负责环节：</p>
      <ol>
        <li>Product Design</li>
        <li>UX Design</li>
        <li>UI Design</li>
      </ol>
      <p>设计成员：1</p>
      <p>
        项目描述：为打通多点 app 和物美商超线上线下的促销活动系统、客户 CRM 等，则需要迭代掉老的线下收银系统，转而开发一个基于安卓平台的 POS 系统，该 POS 的硬件载体为定制化的安卓平板机器，除了旧功能员工换班，收银、结算、打印小票等功能以外，同时支持 app 客户数据同步、线上优惠券使用、组合支付等功能，其中组合支付功能解决了旧系统结账时分位必“抹零”的问题，让超市在收益折损上降到最低。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>Axure</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/axure.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>输出内容</h2>
    <ol>
      <li>高保真交互设计文档</li>
    </ol>
    <h2>可交互原型预览</h2>
    <a href="/dmall_ams/index.html" target="_blank">点击预览 https://portfolio.vicuxd.com/dmall_ams/index.html</a>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/dmall_ams/1.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>