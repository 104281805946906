<template>
  <div class="nav_mobile">
    <router-link to="/about" @click="hide">{{ nav[0] }}</router-link>
    <router-link to="/resume" @click="hide">{{ nav[1] }}</router-link>
    <router-link to="/portfolio" @click="hide">{{ nav[2] }}</router-link>
    <router-link to="/thoughts" @click="hide">{{ nav[3] }}</router-link>
  </div>
</template> 
<script>
export default {
  name: "NavMobile",
  props: {
    nav: {
      type: Array,
      default: function () {
        return ["About", "Resume", "Portfolio", "Thoughts"];
      },
    },
  },
  methods: {
    hide() {
      this.$emit("hide", true);
    },
  },
};
</script>
<style lang="scss">
.nav_mobile {
  display: none;
  background: hsla(240, 1%, 17%, 0.75);
  backdrop-filter: blur(10px);
  position: fixed;
  padding: 0 8px;
  z-index: 2;
  width: 100%;
  left: 0;
  bottom: 0;
  grid-template-columns: repeat(4, 1fr);
  box-shadow: var(--border);
  border-radius: 12px 12px 0 0;
  .router-link-active {
    color: var(--main_5);
    &:active {
      color: var(--main_5);
    }
  }
  > a {
    line-height: 56px;
    text-align: center;
    transition: color 0.2s;
    font-size: 13px;
    color: var(--grey_8);
  }
}
</style>