<template>
  <div class="article">
    <h1>柴二工作室</h1>
    <i>人文、科技领域创作者</i>
      <p>该项目为个人项目</p>
      <p>负责环节：</p>
      <ol>
        <li>Visual Design</li>
        <li>UX Design</li>
        <li>UI Design</li>
        <li>Development</li>
      </ol>
      <p>参与成员：2</p>
      <p>
        项目描述：个人创办的以软件产品设计、开发、绘本设计、平面设计项目为主的工作室，作为乙方，日常负责各个项目的对接执行，作为生产担当，负责包括产品设计与开发在内的各类工作，项目包含网页设计、开发、app 设计、KV 设计等，同时与插画师合作协同生产绘本与各类版式设计。
      </p>
    <br />
    <h2>网站地址</h2>
    <a href="https://chaier.art/" target="_blank">前往查看 https://chaier.art/</a>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/chaier/1.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>