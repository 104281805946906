<template>
  <div class="article">
    <h1>3D Personal Website</h1>
    <i>一个简易的 CMS 个人展示网站</i>
      <p>该项目为个人项目</p>
      <p>负责环节：</p>
      <ol>
        <li>UI Design</li>
        <li>Development</li>
      </ol>
      <p>参与成员：1</p>
      <p>
        项目描述：2017 年初学 CSS 和 jQuery 的我运用 CSS3 的 <code>transform</code> 属性所创作的一个带有 3D 效果的个人网站，并尝试用 jQuery 模拟出路由的效果以及页面之间的过渡动画。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>Sublime Text</li>
      <li>jQuery</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/sublime.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/jquery.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>预览地址</h2>
    <a href="https://3d-personal-website.vicuxd.com" target="_blank">前往查看 https://3d-personal-website.vicuxd.com</a>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/personalwebsite3d/1.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/personalwebsite3d/2.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>