<template>
  <div class="portfolio">
    <Hint />
    <h1 class="portfolio_title" v-if="isListPage">
      {{ content.portfolio_title }}
    </h1>
    <div class="short_line" v-if="isListPage"></div>
    <div class="tab" v-if="isListPage">
      <router-link to="/portfolio">{{ content.portfolio_tab_1 }}</router-link>
      <router-link to="/portfolio/to_b">{{
        content.portfolio_tab_2
      }}</router-link>
      <router-link to="/portfolio/to_c">{{
        content.portfolio_tab_3
      }}</router-link>
    </div>
    <Selector v-if="isListPage" />
    <div class="back" @click="back" v-if="!isListPage">
      <ArrowLeftIcon />
      <div>返回</div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import Hint from "../components/Hint.vue";
import dataEN from "../content/dataEN";
import dataCN from "../content/dataCN";
import Selector from "../components/Selector.vue";
import { ArrowLeftIcon } from "@zhuowenli/vue-feather-icons";
export default {
  name: "Portfolio",
  components: {
    Selector,
    Hint,
    ArrowLeftIcon,
  },
  data() {
    return {
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  methods: {
    back() {
      if (!window.history.state.back) {
        this.$router.push("/portfolio");
      } else {
        this.$router.go(-1);
      }
    },
  },
  computed: {
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
    isListPage() {
      const currentRoute = useRoute().path;
      if (
        currentRoute == "/portfolio" ||
        currentRoute == "/portfolio/to_b" ||
        currentRoute == "/portfolio/to_c"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss">
.short_line {
  margin: 20px 0;
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background: var(--main_5);
}
.viewer-title {
  display: none !important;
}
.article {
  font-size: 16px;
  a {
    word-wrap: break-word;
    color: var(--main_5);
  }
  > img {
    height: 60px;
    & + img {
      margin-left: 30px;
    }
  }
  .img {
    cursor: zoom-in;
    overflow: hidden;
    margin: 20px 0;
    text-align: center;
    font-size: 0;
    img {
      border-radius: 8px;
      height: auto;
      width: auto;
      max-width: 100%;
      & + img {
        margin-top: 20px;
      }
    }
  }
  code {
    line-height: 1.6;
    word-wrap: break-word;
    font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
      Liberation Mono, monospace;
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 90%;
    background-color: var(--main_1);
    color: var(--main_4);
    border-radius: 5px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--grey_9);
    margin: 36px 0 12px 0;
  }
  i {
    border-radius: 4px;
    background: var(--grey_3);
    color: var(--grey_6);
    font-style: normal;
    line-height: 1.6;
    padding: 16px 20px;
    margin: 20px 0;
    overflow: hidden;
    display: block;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      background: var(--main_2);
    }
  }
  p {
    color: var(--grey_7);
    line-height: 1.6;
    & + p {
      margin-top: 16px;
    }
    a {
      color: var(--grey_7);
      text-decoration: underline;
    }
  }
  ol,
  ul {
    color: var(--grey_7);
    line-height: 1.6;
    margin: 0;
    padding: 0 0 0 20px;
    & + p {
      margin-top: 16px;
    }
  }
}
h1.portfolio_title {
  color: var(--grey_9);
  margin: 0;
}
.back {
  user-select: none;
  cursor: pointer;
  display: flex;
  margin: 0 0 36px 0;
  width: 70px;
  color: var(--grey_7);
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  > div {
    transition: color 0.3s;
  }
  > svg {
    transition: color 0.3s;
    margin-right: 12px;
  }
  &:hover {
    color: var(--main_5);
  }
}

.tab {
  line-height: 24px;
  margin: 40px 0 36px 0;

  .router-link-exact-active {
    color: var(--main_5);
    &:hover {
      color: var(--main_5);
    }
  }

  a {
    display: inline-block;
    color: var(--grey_7);
    font-size: 15px;

    & + a {
      margin-left: 28px;
    }
  }
}

.selector {
  display: none;
}

.portfolio_container {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  column-gap: 28px;
  row-gap: 32px;
}
</style>