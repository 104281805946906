<template>
  <div class="container">
    <NavMobile @hide="hideEachContacts" :nav="content.nav" />
    <div class="left">
      <div class="left_container" :class="{ hide: isHide }">
        <div class="mobile_switch" :class="{ highlight: isHide }" @click="hideContacts">
          <span class="pad">{{ text }}</span>
          <span class="phone">Contacts</span>
        </div>
        <div class="left_top">
          <div class="avatar">
            <img src="./assets/avatar.png" alt="" />
          </div>
          <div class="avatar_text">
            <div class="name">{{ content.name }}</div>
            <div class="title">{{ content.title }}</div>
          </div>
        </div>
        <div class="left_middle">
          <Info :mail="true" :title="content.email" content="hlgtsja@163.com">
            <AtSignIcon />
          </Info>
          <Info :title="content.phone" :content="content.phone_content">
            <PhoneIcon />
          </Info>
          <Info :title="content.birthday" :content="content.birthday_content">
            <CalendarIcon />
          </Info>
          <Info :title="content.location" :content="content.location_content">
            <MapIcon />
          </Info>
        </div>
        <div class="left_bottom">
          <a href="https://github.com/justcaliturner" target="_blank" class="icon">
            <GithubIcon />
          </a>
        </div>
      </div>
    </div>
    <div class="right">
      <Nav :nav="content.nav" />
      <router-view />
    </div>
  </div>
</template>

<script>
import dataEN from "./content/dataEN";
import dataCN from "./content/dataCN";
import {
  AtSignIcon,
  PhoneIcon,
  CalendarIcon,
  MapIcon,
} from "@zhuowenli/vue-feather-icons";
import NavMobile from "./components/NavMobile.vue";
import Nav from "./components/Nav.vue";
import Info from "./components/Info.vue";
import GithubIcon from "./components/icon/GithubIcon.vue";
export default {
  components: {
    Info,
    AtSignIcon,
    PhoneIcon,
    CalendarIcon,
    MapIcon,
    GithubIcon,
    Nav,
    NavMobile,
  },
  data() {
    return {
      text: "Hide Contacts",
      height: "",
      isHide: false,
      //
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  computed: {
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
  },
  methods: {
    hideContacts() {
      this.text =
        this.text == "Hide Contacts" ? "Show Contacts" : "Hide Contacts";
      this.isHide = this.isHide == false ? true : false;
    },
    hideEachContacts(bl) {
      this.isHide = bl;
    },
  },
};
</script>

<style lang="scss">
@import "./css/lisa.css";
@import "./css/lisa_mirror.css";
@import "./css/variable.scss";
@import "./css/mobile.scss";

body {
  margin: 0;
  background: var(--grey_0);
  padding: 40px 20px 20px;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

h3 {
  font-size: 17px;
}

::selection {
  background: var(--main_5);
  color: var(--white);
}

::-moz-selection {
  background: var(--main_5);
  color: var(--white);
}

.mobile_lang_switch {
  cursor: pointer;
  // display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 3;
  color: var(--main_3);
  width: 38px;
  height: 38px;
  border-radius: var(--radius);
  font-size: 13px;
  background: linear-gradient(to bottom right,
      var(--main_3) -30%,
      rgba(0, 255, 136, 0.2) 60%);

  &:before {
    content: "";
    border-radius: var(--radius);
    width: 100%;
    height: 100%;
    background: var(--grey_2);
    opacity: 0.65;
    inset: 1px;
    position: absolute;
    z-index: -1;
  }
}

.lang_switch {
  cursor: pointer;
  user-select: none;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 0;
  left: 0;
  color: var(--main_4);
  padding: 6px 20px;
  border-radius: 0 0 var(--radius) 0;
  box-shadow: 1px 1px 0 0 var(--main_0);
  font-size: 13px;
  background: linear-gradient(to bottom right,
      var(--main_5) -20%,
      rgba(0, 255, 136, 0.1) 50%);

  &:before {
    content: "";
    border-radius: var(--radius) 0 var(--radius) 0;
    width: 100%;
    height: 100%;
    background: var(--grey_2);
    opacity: 0.7;
    inset: 1px;
    position: absolute;
    z-index: -1;
  }

  &:hover {
    color: var(--main_5);
    background: linear-gradient(to bottom right,
        var(--main_5) 0%,
        rgba(0, 255, 136, 0.1) 60%);

    &:before {
      opacity: 0.6;
    }
  }
}

.hide {
  height: 186px;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: minmax(260px, 2fr) 6fr;
  grid-column-gap: 20px;
}

.left_container,
.right {
  border-radius: var(--radius);
  box-shadow: var(--border);
}

// left
.left_container {
  transition: height 0.3s;
  border-radius: var(--radius);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--grey_1);
  padding: 40px 36px 36px;
}

.pad {
  display: block;
}

.phone {
  display: none;
}

.left_top {
  text-align: center;
  padding-bottom: 40px;
  box-shadow: var(--border_bottom);
}

.avatar {
  margin: 0 auto;
  background: var(--grey_4);
  border-radius: var(--radius_l);
  width: 150px;
  height: 150px;
  overflow: hidden;

  >img {
    width: 100%;
  }
}

.name {
  margin: 28px 0;
  color: var(--grey_9);
  font-weight: 400;
  font-size: 26px;
}

.title {
  color: var(--grey_9);
  display: inline-block;
  padding: 8px 16px;
  font-size: 13px;
  background: var(--grey_4);
  border-radius: 17px;
}

.left_middle {
  padding: 40px 0;

  >div+div {
    margin-top: 40px;
  }
}

// left_bottom
.left_bottom {
  display: flex;
  justify-content: center;
}

.icon {
  width: 26px;
  height: 26px;
  border-radius: 12px;
  position: relative;

  &:hover>svg {
    color: var(--grey_9);
  }

  >svg {
    transition: color 0.2s;
    width: 100%;
    height: 100%;
    position: absolute;
    color: var(--grey_5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// right
.right {
  background: var(--grey_1);
  padding: 36px;
  position: relative;
}

.mobile_switch {
  display: none;
  cursor: pointer;
  border-right: 1px solid var(--grey_4);
  user-select: none;
  padding: 12px 16px;
  position: absolute;
  background: linear-gradient(to bottom right,
      var(--main_1) 0%,
      rgba(0, 255, 136, 0.1) 60%);
  font-size: 12px;
  color: var(--main_5);
  top: 0;
  right: 0;
  border-radius: 0 var(--radius) 0 var(--radius);
  z-index: 1;
  overflow: hidden;

  &:before {
    transition: opacity 0.2s;
    content: "";
    border-radius: 0 var(--radius) 0 var(--radius);
    width: 100%;
    height: 100%;
    background: var(--grey_2);
    opacity: 0.8;
    inset: 1px;
    position: absolute;
    z-index: -1;
  }
}

.highlight {
  background: linear-gradient(to bottom right,
      var(--main_5) 0%,
      rgba(0, 255, 136, 0.1) 60%);

  &:before {
    opacity: 0.6;
  }
}

.mobile_switch:hover {
  background: linear-gradient(to bottom right,
      var(--main_5) 0%,
      rgba(0, 255, 136, 0.1) 60%);

  &:before {
    opacity: 0.6;
  }
}
</style>
