<template>
  <div class="article">
    <h1>Lingkou 企业测评工具</h1>
    <i>一款面向企业端用户的编程评测工具</i>
      <p>所属公司：<a href="https://leetcode.com/" target="_blank">LeetCode 力扣</a> </p>
      <p>负责环节：</p>
      <ol>
        <li>Design Management</li>
        <li>Product Design</li>
        <li>UX Design</li>
      </ol>
      <p>设计成员：2</p>
      <p>
        项目描述：凭借 C 端 Online Judger 系统的优势和独一无二的编程题题库内容而开发的一款面向企业端用户的编程评测工具，该产品支持多人在线测评，数据统计，自建题目，防作弊，在线面试等一系列功能，为企业人才管理提供便利和高效的服务。
      </p>
      <p>
        项目成果：被多家大型技术企业作为内部评测工具，并进行了多次千人以上规模的在线测评服务。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>Axure</li>
      <li>Sketch</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/axure.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/sketch.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>输出内容</h2>
    <ol>
      <li>交互设计文档</li>
      <li>GUI 设计文档</li>
    </ol>
    <h2>成员管理设计</h2>
    <p>企业测评面向的用户分为 2 种：1、企业内部员工；2、外部人员，故如何统一管理成员是一个问题，并且企业为了员工的隐私，不太愿意在外部系统内输入真实的员工信息，所以这让成员管理变的难上加难，在成员的信息表结构上如何设计主键，如何让员工有密码找回的渠道都是须要解决的问题，我的设计是：使用员工的工号或企业邮箱或手机号进行成员的创建，创建后，后端生成一个唯一的员工 ID 作为系统内的主键，创建时须查重员工号、邮箱和手机号，员工可使用员工或邮箱或手机号进行登录，员工号和邮箱和手机都存在的可用任意方法登录，账号创建之初会初始化一个默认登录密码，须要成员在第一次登录时进行修改，若后期忘记密码，存在邮箱或手机号的成员可使用邮箱或手机号重置密码，没有邮箱仅有员工号的成员可联系管理员在后台重置密码。</p>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lingkou/2.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

    <h2>测评实时回放功能</h2>
    <p>用户测评时的每一个步骤都会被系统记录，并可在管理员端进行回放。</p>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lingkou/5.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>


    <h2>GUI 界面</h2>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lingkou/4.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/lingkou/3.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>