<template>
  <div class="thoughts">
    <h1>{{ content.thoughts_title }}</h1>
    <div class="short_line"></div>
    <div class="thoughts_container">
      <Thought>
        {{ content.thoughts_1 }}
      </Thought>
      <Thought>
        {{ content.thoughts_2 }}
      </Thought>
      <Thought>
        {{ content.thoughts_3 }}
      </Thought>
      <Thought>
        {{ content.thoughts_4 }}
      </Thought>
      <Thought>
        {{ content.thoughts_5 }}
      </Thought>
    </div>
  </div>
</template>

<script>
import dataEN from "../content/dataEN";
import dataCN from "../content/dataCN";
import Thought from "../components/Thought.vue";
export default {
  name: "Thoughts",
  components: {
    Thought,
  },
  data() {
    return {
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  computed: {
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
  },
};
</script>
<style lang="scss">
.short_line {
  margin: 20px 0;
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background: var(--main_5);
}
.thoughts {
  h1 {
    color: var(--grey_9);
  }
}
.thoughts_container {
  padding: 32px 0;
}
.thought + .thought {
  margin-top: 32px;
}
</style>
