<template>
  <div class="portfolio_container">
    <Card
      :title="content.case_1"
      :des="content.case_1_1"
      :img="require(`@/assets/library/0.jpg`)"
      tag="PD / UX / UI"
      link="/portfolio/library"
    />
    <Card
      :title="content.case_3"
      :des="content.case_3_1"
      :img="require(`@/assets/lingkou/0.jpg`)"
      tag="PD / UX"
      link="/portfolio/lingkou"
    />
    <Card
      :title="content.case_4"
      :des="content.case_4_1"
      :img="require(`@/assets/dmall_ams/0.jpg`)"
      tag="PD / UX"
      link="/portfolio/dmallams"
    />
    <Card
      :title="content.case_5"
      :des="content.case_5_1"
      :img="require(`@/assets/dmall_pos/0.jpg`)"
      tag="PD / UX / UI"
      link="/portfolio/dmallpos"
    />
  </div>
</template>

<script>
import dataEN from "../../content/dataEN";
import dataCN from "../../content/dataCN";
import Card from "../../components/Card.vue";

export default {
  name: "PortfolioToB",
  components: {
    Card,
  },
  data() {
    return {
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  computed: {
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
  },
};
</script>