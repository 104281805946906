<template>
  <svg
    fill="currentColor"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 40 40"
  >
    <path d="M20,2 C23.8998886,2 27.2081941,3.19557196 29.9249164,5.58671587 C32.6416388,7.97785978 34,10.914893 34,14.3978155 L34,25.6011218 C34,29.0549963 32.6416388,31.9849446 29.9249164,34.3909668 C27.2081941,36.7969889 23.8998886,38 20,38 C16.1001114,38 12.7918059,36.7969889 10.0750836,34.3909668 C7.35836119,31.9849446 6,29.0549963 6,25.6011218 L6,14.3978155 C6,10.914893 7.35836119,7.97785978 10.0750836,5.58671587 C12.7918059,3.19557196 16.1001114,2 20,2 Z M20,5 C16.815347,5 14.2144623,5.93992069 12.0571673,7.83868037 C10.0636302,9.59330726 9.08019895,11.6174401 9.00471411,14.0868123 L9,14.3978155 L9,25.6011218 C9,28.183727 9.98142569,30.3006356 12.0640885,32.1451129 C14.2208995,34.0552584 16.8189584,35 20,35 C23.1810416,35 25.7791005,34.0552584 27.9359115,32.1451129 C29.9352678,30.3744147 30.919724,28.3526438 30.9952815,25.9088114 L31,25.6011218 L31,14.3978155 C31,11.7865857 30.0194339,9.66641672 27.9428327,7.83868037 C25.7855377,5.93992069 23.184653,5 20,5 Z M20,10 C21.1045695,10 22,10.8954305 22,12 L22,18 C22,19.1045695 21.1045695,20 20,20 C18.8954305,20 18,19.1045695 18,18 L18,12 C18,10.8954305 18.8954305,10 20,10 Z"></path>
  </svg>
</template>
<script>
export default {
  name: "MouseWheelIcon",
};
</script>