<template>
  <div class="icon_box gradient_bg">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "IconBox",
};
</script>
<style lang="scss">
.icon_box {
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50% !important;
  &:before {
    border-radius: 50% !important;
  }
  > svg {
    position: absolute;
    z-index: 1;
    color: var(--main_5);
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>