<template>
  <div class="about">
    <h1>{{ content.aboutMeTitle }}</h1>
    <div class="short_line"></div>
    <p v-html="content.aboutMeContent"></p>
    <h2>{{ content.aboutMeTitle_1 }}</h2>
    <div class="doing">
      <div class="doing_item gradient_bg">
        <div class="doing_item_left">
          <PackageIcon />
        </div>
        <div class="doing_item_right">
          <h3>{{ content.aboutMeCard_1 }}</h3>
          <p>{{ content.aboutMeCard_1_content }}</p>
        </div>
      </div>
      <div class="doing_item gradient_bg">
        <div class="doing_item_left">
          <TrelloIcon />
        </div>
        <div class="doing_item_right">
          <h3>{{ content.aboutMeCard_3 }}</h3>
          <p>{{ content.aboutMeCard_3_content }}</p>
        </div>
      </div>
      <div class="doing_item gradient_bg">
        <div class="doing_item_left">
          <PenToolIcon />
        </div>
        <div class="doing_item_right">
          <h3>{{ content.aboutMeCard_4 }}</h3>
          <p>{{ content.aboutMeCard_4_content }}</p>
        </div>
      </div>
      <div class="doing_item gradient_bg">
        <div class="doing_item_left">
          <CpuIcon />
        </div>
        <div class="doing_item_right">
          <h3>{{ content.aboutMeCard_2 }}</h3>
          <p>{{ content.aboutMeCard_2_content }}</p>
        </div>
      </div>
    </div>
    <div class="clients" v-if="false">
      <h2>{{ content.aboutMeTitle_2 }}</h2>
      <div class="clients_logo">
        <img src="../assets/huawei.png" alt="" />
        <img src="../assets/cochlear.png" alt="" />
        <img src="../assets/coolpad.png" alt="" />
        <img src="../assets/yicai.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import dataEN from "../content/dataEN";
import dataCN from "../content/dataCN";
import {
  PackageIcon,
  PenToolIcon,
  TrelloIcon,
  CpuIcon,
} from "@zhuowenli/vue-feather-icons";
export default {
  name: "About",
  components: {
    PackageIcon,
    PenToolIcon,
    TrelloIcon,
    CpuIcon,
  },
  data() {
    return {
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  computed: {
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
  },
};
</script>
<style lang="scss">
.short_line {
  margin: 20px 0;
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background: var(--main_5);
}
.about {
  h1 {
    color: var(--grey_9);
  }
  h2 {
    margin: 50px 0 26px 0;
    color: var(--grey_9);
  }
  p {
    font-weight: 300;
    color: var(--grey_7);
    line-height: 1.5;
  }
}
.doing {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-template-rows: repeat(2, 2fr);
  gap: 20px;
}
.doing_item {
  display: flex;
  padding: 28px 32px;
  border-radius: var(--radius);
  &:before {
    border-radius: var(--radius);
  }
  h3 {
    font-size: 17px;
    color: var(--grey_8);
  }
  p {
    margin-top: 12px;
    line-height: 28px;
  }
}
.doing_item_left {
  margin-right: 20px;
  > svg {
    margin-top: 2px;
    width: 40px;
    height: 40px;
    color: var(--main_5);
    stroke-width: 1;
  }
}
.clients {
  > h2 {
    margin-bottom: 40px;
  }
}
.clients_logo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  > img {
    width: 100px;
  }
}
</style>
