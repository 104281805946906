<template>
  <div class="mobile_lang_switch" @click="switchLang">{{ mark }}</div>
  <div class="lang_switch" @click="switchLang" v-if="false">{{ mark }}</div>
  <router-view />
</template>
<script>
export default {
  data() {
    return {
      mark: "中",
      isMounted: false,
    }
  },
  watch: {
    isMounted() {
      // 当 isMounted 数据变化时，调用函数，因为在 mounted 钩子里无法直接调用全局函数
      this.autoSwitchLang();
    },
  },
  methods: {
    switchLang() {
      this.$store.commit("switchLanguage"); // 触发全局函数，切换当前的语言
      this.$store.getters.currentLang == "EN" // 读取一个全局数据 - 当前的语言，来显示中英文标记
        ? (this.mark = "中")
        : (this.mark = "EN");
    },
    autoSwitchLang() {
      const currentLang =
        window.navigator.userLanguage || window.navigator.language;
      const lang = currentLang.substring(0, 2);
      this.$store.commit("autoSwitchLanguage", lang); // 触发全局函数，带一个参数，该参数为当前浏览器的环境语言
      this.$store.getters.currentLang == "EN" // 读取一个全局数据 - 当前的语言，来显示中英文标记
        ? (this.mark = "中")
        : (this.mark = "EN");
    },
  },
  mounted() {
    this.isMounted = true;
  },
}
</script>