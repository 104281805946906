<template>
  <div class="selector" @click="toggle">
    <div class="selector_text">
      <span>{{ current == "" ? initCurrent : current }}</span>
      <ChevronDownIcon :class="{ up: this.isShow }" />
    </div>
    <div class="selector_dropdown" v-show="isShow">
      <router-link to="/portfolio" @click="select(content.portfolio_tab_1)">{{
        content.portfolio_tab_1
      }}</router-link>
      <router-link
        to="/portfolio/to_b"
        @click="select(content.portfolio_tab_2)"
        >{{ content.portfolio_tab_2 }}</router-link
      >
      <router-link
        to="/portfolio/to_c"
        @click="select(content.portfolio_tab_3)"
        >{{ content.portfolio_tab_3 }}</router-link
      >
    </div>
  </div>
</template>
<script>
import dataEN from "../content/dataEN";
import dataCN from "../content/dataCN";
import { ChevronDownIcon } from "@zhuowenli/vue-feather-icons";
export default {
  name: "Selector",
  components: {
    ChevronDownIcon,
  },
  props: {},
  data() {
    return {
      current: "",
      isShow: false,
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  methods: {
    toggle() {
      this.isShow = this.isShow == true ? false : true;
    },
    select(el) {
      this.current = el;
    },
  },
  computed: {
    initCurrent() {
      return this.content.portfolio_tab_1;
    },
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
  },
};
</script>
<style lang="scss">
.selector {
  position: relative;
  width: 100%;
  margin-bottom: 32px;
  height: 36px;
  line-height: 36px;
  border-radius: 6px;
  color: var(--grey_6);
  background: var(--grey_2);
  border: 1px solid var(--grey_5);
  padding: 0 12px;
  z-index: 2;
  .router-link-exact-active {
    color: var(--main_5);
    background: var(--grey_4);
    &:hover {
      color: var(--main_5);
    }
  }
}
.selector_text {
  position: relative;
  user-select: none;
  > svg {
    transition: transform 0.2s;
    position: absolute;
    right: 0;
    top: 8px;
  }
  > svg.up {
    transform: rotate(180deg);
  }
}
.selector_dropdown {
  border-radius: 6px;
  border: 1px solid var(--grey_5);
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background: var(--grey_2);
  > a {
    transition: color 0.2s, background 0.2s;
    cursor: pointer;
    display: block;
    height: 36px;
    line-height: 36px;
    color: var(--grey_6);
    padding: 0 12px;
    &:hover {
      color: var(--main_5);
      background: var(--grey_4);
    }
  }
}
</style>