import { createRouter, createWebHashHistory } from 'vue-router'
import About from '../views/About.vue'
import Resume from '../views/Resume.vue'
import Portfolio from '../views/Portfolio.vue'
import Thoughts from '../views/Thoughts.vue'
import PortfolioAll from '../views/portfolio/PortfolioAll.vue'
import PortfolioToB from '../views/portfolio/PortfolioToB.vue'
import PortfolioToC from '../views/portfolio/PortfolioToC.vue'
import Library from '../views/detailPage/Library.vue'
import Lds from '../views/detailPage/Lds.vue'
import DmallAms from '../views/detailPage/DmallAms.vue'
import DmallPos from '../views/detailPage/DmallPos.vue'
import Lingkou from '../views/detailPage/Lingkou.vue'
import CoolpadPCUI from '../views/detailPage/CoolpadPCUI.vue'
import CoolpadMobileUI from '../views/detailPage/CoolpadMobileUI.vue'
import CoolpadMallPC from '../views/detailPage/CoolpadMallPC.vue'
import CoolpadMallMobile from '../views/detailPage/CoolpadMallMobile.vue'
import LisaColor from '../views/detailPage/LisaColor.vue'
import Sce from '../views/detailPage/Sce.vue'
import Seeasas from '../views/detailPage/Seeasas.vue'
import PersonalWebsite3d from '../views/detailPage/PersonalWebsite3d.vue'
import VueResizer from '../views/detailPage/VueResizer.vue'
import Chaier from '../views/detailPage/Chaier.vue'
import Login from '../Login.vue'
import Main from '../Main.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Main',
    redirect: '/about',
    component: Main,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/about',
        component: About,
      },
      {
        path: '/resume',
        component: Resume
      },
      {
        path: '/portfolio',
        component: Portfolio,
        children: [
          {
            path: '',
            component: PortfolioAll,
          },
          {
            path: 'to_b',
            component: PortfolioToB,
          },
          {
            path: 'to_c',
            component: PortfolioToC,
          },
          // 详情页
          {
            path: 'library',
            component: Library,
          },
          {
            path: 'lds',
            component: Lds,
          },
          {
            path: 'lingkou',
            component: Lingkou,
          },
          {
            path: 'dmallAms',
            component: DmallAms,
          },
          {
            path: 'dmallPos',
            component: DmallPos,
          },
          {
            path: 'coolpadpcui',
            component: CoolpadPCUI,
          },
          {
            path: 'coolpadmobileui',
            component: CoolpadMobileUI,
          },
          {
            path: 'coolpadmallpc',
            component: CoolpadMallPC,
          },
          {
            path: 'coolpadmallmobile',
            component: CoolpadMallMobile,
          },
          {
            path: 'lisacolor',
            component: LisaColor,
          },
          {
            path: 'sce',
            component: Sce,
          },
          {
            path: 'seeasas',
            component: Seeasas,
          },
          {
            path: 'personalwebsite3d',
            component: PersonalWebsite3d,
          },
          {
            path: 'vueresizer',
            component: VueResizer,
          },
          {
            path: 'chaier',
            component: Chaier,
          },
        ]
      },
      {
        path: '/thoughts',
        component: Thoughts
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 获取 Cookie 某个值的通用函数
function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
}
// 重定向路由
router.beforeEach((to) => {
  // 若进入非登录页面并且 cookie 的 token 值不是 xxx 那么重定向至 /login
  if (to.meta.requiresAuth && getCookie('token') !== '364665511520') {
    return {
      path: '/login',
      // query: { redirect: to.fullPath },
    }
    // 若进入登录页面并且 cookie 的 token 值是 xxx 那么重定向至 /
  } else if (!to.meta.requiresAuth && getCookie('token') === '364665511520') {
    return {
      path: '/',
    }
  }
})

export default router
