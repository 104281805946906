<template>
  <div class="progress">
    <div>
      <div class="progress_top">
        <span class="progress_name">{{ name }}</span>
        <span class="perc">{{ perc + "%" }}</span>
      </div>
      <div class="perc_line">
        <div :style="{width: perc + '%'}"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Progress",
  props: {
    name: {
      type: String,
      default: "Web Design",
    },
    perc: {
      type: Number,
      default: 20,
    },
  },
};
</script>
<style lang="scss">
.progress_top {
  display: flex;
  span {
    line-height: 20px;
  }
}
.progress_name {
  color: var(--grey_8);
  font-size: 15px;
}
.perc {
  margin-left: 8px;
  color: var(--grey_6);
}
.perc_line {
  position: relative;
  margin-top: 10px;
  height: 8px;
  width: 100%;
  background: var(--grey_4);
  border-radius: 4px;
  > div {
    position: absolute;
    border-radius: 4px;
    height: 100%;
    background: var(--main_4);
  }
}
</style>