<template>
  <div class="article">
    <h1>Vue Resizer</h1>
    <i>基于 Vue.js 的一系列拖拽缩放组件</i>
      <p>该项目为个人项目：<a href="https://github.com/justcaliturner/vue-resizer" target="_blank">Github 项目地址</a></p>
      <br>
      <p>负责环节：</p>
      <ol>
        <li>Development</li>
      </ol>
      <p>参与成员：1</p>
      <p>
        项目描述：Vue Resizer 是基于 Vue.js 的一系列拖拽缩放组件，同时支持桌面端和移动端，这个组件的核心知识点是“性能节流”，当鼠标拖动时候监听函数会不间断的触发，这会引起性能问题，因为现实不需要在鼠标拖动的 0.0001 秒的时候就触发，所以使用 <code>setTimeout</code> 来创建函数的触发时间间隙，这样即可实现性能的节流。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>VS Code</li>
      <li>Vue.js</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/vscode.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/vue.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>网站地址</h2>
    <a href="https://vue-resizer.vicuxd.com/" target="_blank">前往查看 https://vue-resizer.vicuxd.com/</a>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/vueresizer/1.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>