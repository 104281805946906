<template>
  <div class="article">
    <h1>Simple Code Editor</h1>
    <i>简易 Web 代码编辑器 for Vue.js</i>
      <p>该项目为个人项目：<a href="https://github.com/justcaliturner/simple-code-editor" target="_blank">Github 项目地址</a></p>
      <br>
      <p>负责环节：</p>
      <ol>
        <li>UI Design</li>
        <li>Development</li>
      </ol>
      <p>参与成员：1</p>
      <p>
        项目描述：Simple Code Editor 是一个基于 Vue.js 的轻量级代码编辑器，与那些自己实现文本渲染的高级编辑器不同，它的实现原理很简单，通过上层的文本输入和下层的 highlight 文本同步来实现实时代码高亮的功能，所以它的唯一难点就在于在各种不同的情况下同步这两层的坐标位置，比如文本滚动时，tab 键缩进时，容器宽度发生变化时等等，当然它还可以当作高亮代码块来使用。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>VS Code</li>
      <li>Vue.js</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/vscode.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/vue.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>实例展示</h2>
    <i>Dark theme</i>
    <CodeEditor width="100%" :value="code" font_size="15px"/>
    <br>
    <i>Light theme</i>
    <CodeEditor width="100%" :value="code" theme="light" font_size="15px"/>
    <h2>文档地址</h2>
    <a href="https://sce.vicuxd.com/" target="_blank">前往查看 https://sce.vicuxd.com/</a>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
import CodeEditor from 'simple-code-editor'
export default {
  name: "Library",
  components: {
    CodeEditor,
    VLazyImage
  },
    data(){
    return {
      code: `import CodeEditor from 'simple-code-editor';
export default {
    components: {
      CodeEditor
    }
}`
    }
  }
};
</script>