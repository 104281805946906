<template>
  <div class="article">
    <h1>LC Design Language System</h1>
    <i>为统一平台产品体验而定制的一整套通用设计方案</i>
      <p>所属公司：<a href="https://leetcode.com/" target="_blank">LeetCode 力扣</a> </p>
      <p>负责环节：</p>
      <ol>
        <li>Design Management</li>
        <li>UI Design for Desktop</li>
        <li>API Design for Components</li>
        <li>CSS Coding for Rich Text</li>
      </ol>
      <p>设计成员：2</p>
      <p>
        项目描述：为统一平台内所有产品的体验而构建的一整套设计方案，从 Branding、颜色系统、文字、通用样式、到组件、图标、富文本样式的一整套设计语言，该设计语言通过引入不同的颜色系统原生支持亮色、暗色主题或其他任何主题，同时也首创在 sketch 软件内部实现引入颜色系统的方法，让同一组件库在不需要重复制作的情况下实现不同的主题展示，该方法大大降低了设计团队的工作产出，让后续的产品设计工作变得高效便捷，该项目由包括我在内的两名设计师共同执行维护，我负责整体的结构设计及桌面端部分。
      </p>
      <p>
        项目成果：更好的统一了平台 B 端、C 端、桌面端、移动端各端产品的视觉和用户体验，大大降低了设计团队的产出效率及开发过程中的沟通成本，同时也节省了前端工程师在公共样式和通用组件开发上的开发成本，让平台的产品输出变得高效而便捷。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>Sketch</li>
      <li>iCloud</li>
      <li>VS Code</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/sketch.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/icloud.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/vscode.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>输出内容</h2>
    <ol>
      <li>GUI 设计文档</li>
      <li>设计文件仓库</li>
      <li>HTML + CSS 富文本样式</li>
    </ol>
    <h2>设计系统结构体系</h2>
    <p>
      从业务角度，公司产品可分为企业端和用户端，从设备载体角度，可分为有移动端和桌面端，为了更好的统筹各端的设计资源、提高通用性，设计系统的结构体系如下：
    </p>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lds/2.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

    <h2>团队协作</h2>
    <p>该项目由我主导，与另一名 UI 设计师共同执行维护，对于当时 Figma 这类云端设计工具还未普及的时代，实时共享，协作设计，组件库实时更新等功能对于本地客户端设计工具来说，是一个较难解决的问题，我们通过 Sketch Library 和 iCloud 的组合，实现了在设计团队内部共享、协同设计，自动更新组件库等功能，真正实现无感迭代。</p>

    <h2>Icon 图标</h2>
    <p>Icon 通过 Sketch 的 Symbol 作为公共库，支持颜色的替换，表现类型分为 border 和 filled 两种。</p>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lds/3.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

    <h2>Common Style 通用样式</h2>
    <p>Common Style 主要包含颜色、描边和阴影一些常用的通用样式，在 Sketch 内作为 Layer Style 定义，也是一个单独的库，有 Light 和 Dark 两个文件，可导入任意设计文件，通过切换 Light 和 Dark 这两个文件的应用从而达到设计文件的主题切换功能，舍去了重复设计的冗余步骤。</p>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lds/5.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

    <h2>Typography 文字版式</h2>
    <p>Typography 是文字的版式集合，其罗列了所有可能用到的文字版式，通过归类，让设计紧紧有条。</p>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lds/4.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

    <h2>Component 组件</h2>
    <p>组件也是以 Sketch Symbol 的形式作为库引入设计文件，通过切换 Common Style 可自动在 Light 和 Dark 之中转换。</p>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lds/6.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

    <h2>Rich Text Style 富文本样式</h2>
    <p>通用富文本样式主要应用于平台的文章展示</p>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lds/7.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/lds/8.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>