<template>
  <div class="login">
    <div class="contact_switch" @click="showContact">{{content.login_contact}}</div>
    <div class="login_contact gradient_bg_light" v-show="isShowContact">
      <div class="login_back" @click="back">
        <ArrowLeftIcon />
      </div>
      <p v-html="content.login_contact_info">
      </p>
    </div>
    <div class="login_container gradient_bg_light">
        <h2>{{content.login_title}}</h2>
        <div class="notification" v-if="false">
          {{ content.login_hints }}
        </div>
        <div class="input">
          <div class="error" v-show="isError">{{content.login_password_hint}}</div>
          <input @focus="hideError" type="password" :placeholder="content.login_placeholder" v-model="key" v-on:keyup.enter="submit" />
        </div>
        <button @click="submit">
          <span>{{content.login_button}}</span>
          <ArrowRightIcon />
        </button>
    </div>
  </div>
</template>
<script>
import dataEN from "./content/dataEN";
import dataCN from "./content/dataCN";
import { ArrowLeftIcon, ArrowRightIcon } from "@zhuowenli/vue-feather-icons";
export default {
  name: "Login",
  components: {
    ArrowRightIcon,
    ArrowLeftIcon,
  },
  data() {
    return {
      isShowContact: false,
      key: "",
      isError: false,
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  computed: {
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
  },
  methods: {
    setCookie(cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toGMTString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    back() {
      this.isShowContact = false;
    },
    showContact() {
      this.isShowContact = this.isShowContact == true ? false : true;
    },
    submit() {
      if (this.key === '4813') {
        alert('Your password has been expired, please contact me to get the new one.')
      }
      const key = this.key.split("");
      const random = [
        115,
        0,
        3646,
        65,
        46,
        6,
        20,
        46,
        36,
        55,
        665,
        1520,
      ];
      let token = "";
      for (let i = 0; i < key.length; i++) {
        token += random[key[i]];
      }
      this.setCookie("token", token);
      this.$router.push("/");
      this.isError = true;
    },
    hideError() {
      this.isError = false;
    },
  },
};
</script>
<style lang="scss">
.notification {
  margin: 0 auto;
  width: 90%;
  text-align: center;
  font-size: 14px;
  line-height: 1.6;
  color: var(--grey_6);
  margin-bottom: 28px;
}
.login {
  position: absolute;
  margin-top: -40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
}

.login_back {
  cursor: pointer;
  transition: color 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey_6);
  border-radius: 0 0 var(--radius_s) 0;

  >svg {
    width: 22px;
    height: 22px;
  }

  &:hover {
    color: var(--main_3);
  }

  &:active {
    color: var(--main_2);
  }
}

.login_contact {
  display: flex;
  align-items: center;
  left: 20px;
  top: 0;
  padding: 16px 24px;
  width: calc(100% - 40px);
  height: 100%;
  position: absolute !important;
  z-index: 2 !important;
  border-radius: var(--radius_s) !important;
  overflow: hidden;

  p {
    font-size: 14px;
    line-height: 1.8;
    color: var(--grey_7);

    span {
      font-size: 28px;
    }

    a {
      font-style: normal;
      color: var(--main_5);
    }
  }
}

.login_container {
  padding: 32px 20px;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: var(--radius_s) !important;
  overflow: hidden;

  h2 {
    text-align: center;
    color: var(--grey_7);
    margin: 12px 0 28px 0;
  }
}

.input {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;

  .error {
    border-radius: 4px;
    padding: 0 6px;
    position: absolute;
    height: 32px;
    line-height: 32px;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    font-size: 14px;
    font-weight: 500;
    background: var(--red_5);
  }
}

input {
  transition: border-color 0.2s;
  color: var(--grey_9);
  display: block;
  padding: 6px 8px;
  width: 100%;
  font-size: 16px;
  line-height: 28px;
  background: var(--grey_1);
  border: 1px solid var(--grey_5);
  border-radius: 6px;

  &:hover {
    border-color: var(--grey_6);
  }

  &:focus {
    border-color: var(--main_5);
    outline: none;
  }
}

button {
  transition: background 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px;
  width: 90%;
  font-size: 18px;
  line-height: 28px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 32px;
  border-radius: 6px;
  background: var(--main_5);
  border: none;
  color: var(--grey_0);

  >svg {
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }

  &:hover {
    background: var(--main_4);
  }

  &:active {
    background: var(--main_3);
  }
}

.contact_switch {
  cursor: pointer;
  user-select: none;
  padding: 12px 16px;
  position: absolute;
  background: linear-gradient(to bottom right,
      var(--main_1) 0%,
      rgba(0, 255, 136, 0.1) 60%);
  font-size: 12px;
  color: var(--main_5);
  top: 0;
  right: 20px;
  border-radius: 0 var(--radius_s) 0 var(--radius_s);
  z-index: 3;
  overflow: hidden;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: var(--grey_2);
    opacity: 0.8;
    inset: 1px;
    position: absolute;
    z-index: -1;
  }
}

.contact_switch:hover {
  background: linear-gradient(to bottom right,
      var(--main_5) 0%,
      rgba(0, 255, 136, 0.1) 60%);

  &:before {
    opacity: 0.6;
  }
}
</style>