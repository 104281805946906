<template>
  <div class="info">
    <IconBox>
      <slot></slot>
    </IconBox>
    <div class="info_right">
      <div class="info_title">{{ title }}</div>
      <div v-if="!mail" class="info_content">{{ content }}</div>
      <a v-if="mail" :href="'mailto:' + content" class="info_content" target="_blank">{{
        content
      }}</a>
    </div>
  </div>
</template>

<script>
import IconBox from "./IconBox.vue";
export default {
  name: "Info",
  components: {
    IconBox,
  },
  props: {
    mail: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "PHONE",
    },
    content: {
      type: String,
      default: "+1 (213) 318-8305",
    },
  },
};
</script>

<style lang="scss">
@import "../css/variable.scss";

.info {
  display: grid;
  grid-template-columns: 48px auto;
  grid-column-gap: 20px;
}

.info_right {
  overflow: hidden;
}
.info_title {
  color: var(--grey_6);
  font-size: 12px;
  line-height: 20px;
  margin: 0 0 4px 0;
}
.info_content {
  display: block;
  color: var(--grey_7);
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
