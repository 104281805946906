<template>
  <div class="article">
    <h1>多点 - 云 POS</h1>
    <i>连接 app 用户融合线上促销活动的商超 POS</i>
      <p>所属公司：<a href="https://dmall.com/" target="_blank">Dmall 多点</a> </p>
      <p>负责环节：</p>
      <ol>
        <li>Product Design</li>
        <li>UX Design</li>
        <li>UI Design</li>
      </ol>
      <p>设计成员：1</p>
      <p>
        项目描述：多点生活是一个生鲜类电商平台，主打 1 小时极速配送服务，日常运营的促销活动更是层出不穷，经常推陈出新，故企业内部有大量活动页面的设计和开发需求，在此背景下 AMS 活动管理系统便孕育而生，该系统把活动页面中常用的一些模块抽象出来做成了通用的组件，并通过简单的配置便可在页面中使用，如：活动倒计时模块、商品模块、轮播图模块、促销模块、导航模块等，运营可通过自行配置、再通过审核机制快速上线自己想要的活动页面，在公司内大大减少了设计和前端的开发工作量，提高生产效率。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>Axure</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/axure.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>输出内容</h2>
    <ol>
      <li>高保真交互设计文档</li>
    </ol>
    <h2>可交互原型预览</h2>
    <a href="/dmall_pos/start.html" target="_blank">点击预览 https://portfolio.vicuxd.com/dmall_pos/start.html</a>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/dmall_pos/5.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/dmall_pos/2.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/dmall_pos/4.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>