<template>
  <div class="article">
    <h1>LC Library 力扣题库系统</h1>
    <i>为优化冗长的编程题创建流程而定制的出题解决方案</i>
      <p>所属公司：<a href="https://leetcode.com/" target="_blank">LeetCode 力扣</a> </p>
      <p>负责环节：</p>
      <ol>
        <li>Product Design</li>
        <li>UX Design</li>
        <li>UI Design</li>
      </ol>
      <p>设计成员：1</p>
      <p>
        项目描述：算法题是 Online Judger
        的核心内容，而出题是个较复杂的过程，如何轻松、快速、高质量的出题是
        Content Creator 们的核心诉求，也是产品的研发目的。LC Library 基于自研
        Online Judger
        系统，通过构建优质体验的用户界面工具来大大减少出题难度，产品前端技术选型为
        React，API 技术使用了当时较为新颖的 GraphQL，UI 开发基于公司自研的 LDS
        设计系统的桌面端组件库，原生支持 Light Mode 和 Dark Mode。
      </p>
      <p>
        项目成果：得到 Content Creator
        们的一致好评，而后计划开放给部分外部用户使用，以丰富题库内容和提高题目的可维护性。
      </p>
    
    <h2>生产工具</h2>
    <ul>
      <li>Google Docs</li>
      <li>Axure</li>
      <li>Sketch</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/google_docs.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/axure.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/sketch.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>输出内容</h2>
    <ol>
      <li>产品文档</li>
      <li>交互设计文档</li>
      <li>GUI 设计文档</li>
    </ol>
    <h2>界面交互设计思路</h2>
    <p>
      相比做题，出题的操作流程明显更为复杂，从产品的拓展性来说，我们的愿景是让平台有生产
      UGC
      的能力，让用户主动参与创作高质量题目，所以我的思路是尽可能的让出题操作更简单明了，适合让
      C
      端用户上手，并且不失工具的专业性。对于平台用户来说做题的流程大多都比较熟悉，所以将出题的操作交互体验做的与做题的体验尽可能接近，那么可大大减少用户的操作陌生度，所以在界面的基础布局上与做题界面相同，也以左右布局为主，左边为题目配置区，右边为代码编辑区。
    </p>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/library/5.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>
    <h2>题目结构</h2>
    <p>要设计出题流程，我们先要了解一道算法题的基本组成结构，如下：</p>
    <ol>
      <li>题目名称</li>
      <li>难度，用于描述题目的难易度</li>
      <li>题目描述，也就是题面，用于描述题目内容</li>
      <li>
        做题模版，用于用户在答题时快速编写逻辑代码的初始化模版，主要由 2
        部分组成：
      </li>
      <ol>
        <li>
          主函数，用于在其内部编写逻辑代码并被 Judger
          执行，该函数的返回结果即为题目的答案
        </li>
        <li>
          入参，用于 Judger 输入测试用例，以判断不同的输入下，所有的输出的结果是否都符合预期
        </li>
      </ol>
    </ol>
    <br>
    <CodeEditor width="100%" :value="code" :read_only="true" font_size="14px"/>
    <br>
    <h2>出题流程</h2>
    <p>
      了解了题目的基本结构之后，就可以着手设计出题的流程，当然该过程需要算法工程师的专业支持，在一番讨论和设计后得出了以下的出题流程步骤：
    </p>
    <ol>
      <li>编辑题目描述，此步骤用于编辑题目的基本信息和题面描述</li>
      <li>
        编辑题目配置，此步骤用于配置题目的做题模版和限制信息，如：做题模版中的主函数、参数、结果匹配方式、结果返回格式，以及题目的支持语言和单个语言的内存和时间限制。
      </li>
      <li>
        编辑题解代码，题解代码作为标准答案代码，在出题过程中用于生成预期的输出结果，在用户做题时用于判断用户代码是否正确的标准。
      </li>
      <li>
        添加测试用例，测试用例作为入参在出题过程中用于生成题解代码的预期输出结果。
      </li>
      <li>
        生成输出结果，输出结果作为输入测试用例后代码的预期输出结果在出题过程中用于调试代码的输入输出是否符合预期。
      </li>
      <li>
        提交并保存题解代码，保存的题解代码作为题目的标准答案用以确保题目的正常运行以及用户在做题时代码逻辑正确与否的依据。
      </li>
      <li>发布题目，发布题目的操作用于将题目发布至线上。</li>
    </ol>
    <h2>GUI 用户界面</h2>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/library/4.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
import CodeEditor from 'simple-code-editor'
export default {
  name: "Library",
  components: {
    CodeEditor,
    VLazyImage
  },
  data(){
    return {
      code: `// twoSum 为主函数
// nums 和 target 为入参
// 编写代码后，在该函数内返回结果即可
var twoSum = function(nums, target) {

};`
    }
  }
};
</script>