<template>
  <div class="article">
    <h1>Coolpad Mall for Desktop</h1>
    <i>酷派桌面端线上商城</i>
      <p>所属公司：Coolpad 酷派</p>
      <p>负责环节：</p>
      <ol>
        <li>UX Design</li>
        <li>UI Design</li>
      </ol>
      <p>设计成员：1</p>
      <p>
        项目描述：酷派商城是一个酷派自建的 3C 数码电商平台，其销售的商品以自研手机、和其他智能设备为主。该项目是在新的品牌形象基础上，结合积分、促销等新功能进行的大版本重构，同时支持桌面端和手机端，UI 上我为 PC 和 Mobile 各自建立了一套组件库，为后期的设计迭代和前端开发提供更高效的协同产出，在前端开发阶段配合工程师 coding 基础的 CSS 样式，让设计稿的还原度更高，同时也让前端更专注数据层的研发。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>Axure</li>
      <li>Sketch</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/axure.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/sketch.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>输出内容</h2>
    <ol>
      <li>交互设计文档</li>
      <li>GUI 设计文档</li>
    </ol>
    <h2>商品详情和购物车页部分交互</h2>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/coolpad_mall_pc/2.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/coolpad_mall_pc/3.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>
    <br>
    <h2>GUI 界面</h2>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/coolpad_mall_pc/4.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/coolpad_mall_pc/5.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/coolpad_mall_pc/6.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/coolpad_mall_pc/7.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/coolpad_mall_pc/8.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>