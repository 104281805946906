<template>
  <div class="portfolio_container">
    <Card
      :title="content.case_1"
      :des="content.case_1_1"
      :img="require(`@/assets/library/0.jpg`)"
      tag="PD / UX / UI"
      link="/portfolio/library"
    />
    <Card
      :title="content.case_2"
      :des="content.case_2_1"
      :img="require(`@/assets/lds/0.jpg`)"
      tag="UI / CSS"
      link="/portfolio/lds"
    />
    <Card
      :title="content.case_3"
      :des="content.case_3_1"
      :img="require(`@/assets/lingkou/0.jpg`)"
      tag="PD / UX"
      link="/portfolio/lingkou"
    />
    <Card
      :title="content.case_4"
      :des="content.case_4_1"
      :img="require(`@/assets/dmall_ams/0.jpg`)"
      tag="PD / UX"
      link="/portfolio/dmallams"
    />
    <Card
      :title="content.case_5"
      :des="content.case_5_1"
      :img="require(`@/assets/dmall_pos/0.jpg`)"
      tag="PD / UX / UI"
      link="/portfolio/dmallpos"
    />
    <Card
      :title="content.case_6"
      :des="content.case_6_1"
      :img="require(`@/assets/coolpad_pc_ui/0.jpg`)"
      tag="UI / CSS"
      link="/portfolio/coolpadpcui"
    />
    <Card
      :title="content.case_7"
      :des="content.case_7_1"
      :img="require(`@/assets/coolpad_mobile_ui/0.jpg`)"
      tag="UI / CSS"
      link="/portfolio/coolpadmobileui"
    />
    <Card
      :title="content.case_8"
      :des="content.case_8_1"
      :img="require(`@/assets/coolpad_mall_pc/0.jpg`)"
      tag="UX / UI"
      link="/portfolio/coolpadmallpc"
    />
    <Card
      :title="content.case_9"
      :des="content.case_9_1"
      :img="require(`@/assets/coolpad_mall_mobile/0.jpg`)"
      tag="UX / UI"
      link="/portfolio/coolpadmallmobile"
    />
    <Card
      :title="content.case_10"
      :des="content.case_10_1"
      :img="require(`@/assets/lisa_color/0.jpg`)"
      tag="PD / Dev"
      link="/portfolio/lisacolor"
    />
    <Card
      :title="content.case_11"
      :des="content.case_11_1"
      :img="require(`@/assets/sce/0.jpg`)"
      tag="UI / Dev"
      link="/portfolio/sce"
    />
    <Card
      :title="content.case_12"
      :des="content.case_12_1"
      :img="require(`@/assets/seeasas/0.jpg`)"
      tag="UI / Dev"
      link="/portfolio/seeasas"
    />
    <Card
      :title="content.case_13"
      :des="content.case_13_1"
      :img="require(`@/assets/personalwebsite3d/0.jpg`)"
      tag="UI / Dev"
      link="/portfolio/personalwebsite3d"
    />
    <Card
      :title="content.case_14"
      :des="content.case_14_1"
      :img="require(`@/assets/vueresizer/0.jpg`)"
      tag="Dev"
      link="/portfolio/vueresizer"
    />
    <Card
      :title="content.case_15"
      :des="content.case_15_1"
      :img="require(`@/assets/chaier/0.jpg`)"
      tag="UX / UI / Dev"
      link="/portfolio/chaier"
    />
    <Card
      :title="content.case_16"
      :des="content.case_16_1"
      :img="require(`@/assets/personal_website.jpg`)"
      tag="Link"
      :isLink="true"
      link="https://www.vicuxd.com/"
    />
  </div>
</template>

<script>
import dataEN from "../../content/dataEN";
import dataCN from "../../content/dataCN";
import Card from "../../components/Card.vue";

export default {
  name: "PortfolioAll",
  components: {
    Card,
  },
  data() {
    return {
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  computed: {
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
  },
};
</script>