<template>
  <div class="nav">
    <router-link to="/about">{{ nav[0] }}</router-link>
    <router-link to="/resume">{{ nav[1] }}</router-link>
    <router-link to="/portfolio">{{ nav[2] }}</router-link>
    <router-link to="/thoughts">{{ nav[3] }}</router-link>
  </div>
</template> 
<script>
export default {
  name: "Nav",
  props: {
    nav: {
      type: Array,
      default: function () {
        return ["About", "Resume", "Portfolio", "Thoughts"];
      },
    },
  },
};
</script>
<style lang="scss">
.nav {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 20px;
  background: var(--grey_2);
  box-shadow: var(--border);
  border-radius: 0 var(--radius) 0 var(--radius);
  .router-link-active {
    color: var(--main_5);
    &:hover {
      color: var(--main_5);
    }
  }
  > a {
    transition: color 0.2s;
    font-size: 15px;
    margin: 0 30px;
    color: var(--grey_8);
    &:hover {
      color: var(--grey_6);
    }
  }
}
</style>