<template>
  <div class="article">
    <h1>Seeasas</h1>
    <i>交互式 CSS 学习站点</i>
      <p>该项目为个人项目</p>
      <br>
      <p>负责环节：</p>
      <ol>
        <li>UI Design</li>
        <li>Development</li>
      </ol>
      <p>参与成员：1</p>
      <p>
        项目描述：Seeasas 是一个可实时预览的 HTML + CSS 学习工具，致力于提供优质的 CSS 学习内容和学习体验，目前处于 Demo 阶段，内容编辑还未完成。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>VS Code</li>
      <li>Vue.js</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/vscode.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/vue.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>线上地址</h2>
    <a href="https://www.seeasas.com/" target="_blank">前往查看 https://www.seeasas.com/</a>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/seeasas/1.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>