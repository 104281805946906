<template>
  <svg
    fill="currentColor"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 40 40"
  >
    <path
      d="M20,2 C23.8998886,2 27.2081941,3.19557196 29.9249164,5.58671587 C32.6416388,7.97785978 34,10.914893 34,14.3978155 L34,25.6011218 C34,29.0549963 32.6416388,31.9849446 29.9249164,34.3909668 C27.2081941,36.7969889 23.8998886,38 20,38 C16.1001114,38 12.7918059,36.7969889 10.0750836,34.3909668 C7.35836119,31.9849446 6,29.0549963 6,25.6011218 L6,14.3978155 C6,14.1576139 6.0064607,13.9200088 6.01938209,13.685 L6.0200487,13.6598304 C6.19380408,10.498102 7.49696947,7.80706378 9.92954487,5.58671587 C12.5492415,3.19557196 15.7393932,2 19.5,2 C19.5972069,2 19.6940327,2.00079883 19.7904773,2.00239649 L20,2 L20,2 Z M20,5 L20,13 C20,14.6568542 18.6568542,16 17,16 L9,16 L9,25.6011218 C9,28.183727 9.98142569,30.3006356 12.0640885,32.1451129 C14.2208995,34.0552584 16.8189584,35 20,35 C23.1810416,35 25.7791005,34.0552584 27.9359115,32.1451129 C29.9352678,30.3744147 30.919724,28.3526438 30.9952815,25.9088114 L31,25.6011218 L31,14.3978155 C31,11.7865857 30.0194339,9.66641672 27.9428327,7.83868037 C25.7855377,5.93992069 23.184653,5 20,5 Z"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "MoustLeftButtonIcon",
};
</script>