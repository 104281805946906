export default {
    login_title: "Vic's Portfolio",
    login_contact: "Contact",
    login_hints: 'If the experience of working at big companies and an impressive resume are necessary for you, please do not continue to visit. If you care more about personal ability, conduct, responsibility, and pragmatism, I will be glad to introduce myself to you.',
    login_button: "Enter",
    login_placeholder: 'Password',
    login_contact_info: `<span>👋 Hi, I am Vic, </span>I'm a product designer, this is my portfolio, if you are interested in what I did, please feel free to contact me:
    <a href="mailto:hlgtsja@163.com">hlgtsja@163.com</a>`,
    login_password_hint: "Invalid",
    aboutMeTitle: 'About Me',
    aboutMeContent: `I'm a product designer and a front-end developer with over 8 years of working experience in the internet industry. I focus on software product design including business design, functionality design, interaction design, and user interface design. I am also keen on front-end development and have over 5 years of self-taught and development experience.
    <br />
    In my work, I am interested in blending businesses and technologies in product design and building the design system to be used in production. I am familiar with each step of the software production process, and I have some insight into efficient cooperation and collaboration, especially the collaboration between developers and designers. I also have the product sense with strong empathy and am good at insight and observation. If you need a product designer who requires the abilities of collaboration, development, and business and has high requirements for user experience, I will be happy to introduce myself, thank you.`,
    aboutMeTitle_1: "What I'm Doing",
    aboutMeCard_1: "Product Design",
    aboutMeCard_1_content: "I am familiar with each step of the software production process and have strong product owner awareness with experience both on the client-side and business-side.",
    aboutMeCard_2: "Web Development",
    aboutMeCard_2_content: "From operating DOM with JS or jQuery to SPA, I have frameless dev and Vue.js application dev abilities, and deep insight into component dev and CSS adaptive page layout.",
    aboutMeCard_3: "UX Design",
    aboutMeCard_3_content: "I am familiar with the user-centered design philosophy and interaction design guidelines for desktop and mobile devices with strong empathy and good expression.",
    aboutMeCard_4: "UI Design",
    aboutMeCard_4_content: "I am good at component-based design like building and using design systems in production and I have design experience in desktop web, mobile web, and mobile apps.",
    aboutMeTitle_2: "Brands I have served",
    nav: ["About", "Resume", "Portfolio", "Thoughts"],
    email: "EMAIL",
    phone: "PHONE",
    birthday: "BIRTHDAY",
    location: "LOCATION",
    phone_content: "(+86)15000532387",
    birthday_content: "Oct 27, 1990",
    location_content: "Shanghai, China",
    name: "Vic",
    title: "Product designer",
    // resume
    resume_title: "Resume",
    resume_title_1: "Education",
    education_1: "Shanghai Business School",
    education_1_date: "Sept 2009 → Jun 2013",
    education_1_des: "Art Design of New Media",
    education_1_edu: "Bachelor's degree",
    education_1_degree: "",
    experience_title: "Experience",
    experience_1_1: "Chaier",
    experience_1_2: "Since 2020 / 10",
    experience_1_3: "Title：Studio owner",
    experience_1_4: "Website: ",
    experience_1_5_1: "Background",
    experience_1_5: "This is a studio I created and the business includes software design, web development, and graphic design.",
    experience_1_6_1: "Responsibilities",
    experience_1_6: "As a studio operator, I am responsible for the daily implementation of various projects. As a production person, I am responsible for all kinds of work including product design and development. The projects include web page design, front-end development, app design, KV design, etc. At the same time, I cooperate with the illustrator to design picture books and graphic works.",
    experience_2_1: "LeetCode",
    experience_2_2: "May 2018 → Jan 2020",
    experience_2_3: "Title：Product design leader",
    experience_2_4: "Website: ",
    experience_2_5_1: "Background",
    experience_2_5: "As a founding team member of Leetcode's domestic company, I built the company's product design system and product design team from 0 to 1. I was responsible for the product design both on the client-side and business-side, including some overseas businesses, I also participated in the planning of the company's product strategy and business direction, and designed the cutting-edge functionality together with core technical members. The domestic company achieved a profit and loss balance within two years.",
    experience_2_6: "Responsibilities",
    experience_2_7: ["Responsible for overall product design planning, thinking, disassembling, and strategy optimization of the whole process.",
    "Build the design system for unified product design specifications, and build and maintain the UI component library.",
    "Responsible for the UX and UI design of the business-side and client-side products.",
    "Formulate medium and long-term product planning, find out user needs and product experience optimization points through data analysis, user feedback collection, and other means, output valuable strategy optimization schemes or suggestions and promote the implementation.",
    "Follow up on the progress of the project, effectively cooperate with functional teams such as R & D, operation and design, promote rapid iteration of relevant product functions, and achieve product growth.",
    "Combined with industry understanding, build user scenarios, promote continuous optimization of user experience, and enhance user value."],
    experience_2_8: "Projects",
    experience_2_9: ["LeetCode Mock Interview",
    "Lingkou Enterprise Assessment Tool",
    "LDS design system",
    "LC Library"],
    experience_2_9_1: ["https://leetcode.cn/company/",
    "/portfolio/lingkou",
    "/portfolio/lds",
    "/portfolio/library"],
    experience_2_10_1: "Why I left",
    experience_2_10: "For family reasons, I started to run my own studio - Chaier",
    experience_3_1: "Quanmin TV",
    experience_3_2: "Oct 2017 → May 2018",
    experience_3_3: "Title：UX manager",
    experience_3_4_1: "Background",
    experience_3_4: "With the speed development of the live streaming industry, I was invited by my colleagues to participate in the innovative marketing projects of the live streaming industry to seek a new and transformative marketing model for the live streaming industry and participated in the earliest prototype of live e-commerce.",
    experience_3_5: "Responsibilities",
    experience_3_6: ["Responsible for the design of products and operation activities of various business lines, including live broadcast and game business, controlling the overall quality and efficiency, and mining design opportunities based on the analysis and evaluation of the current situation.",
    "Based on the company's strategy, formulate matching design and team management strategies, lead the team to deepen platform marketing business, actively explore solutions, and help achieve business goals.",
    "Lead the team to refine design methods from practice, precipitate excellent cases, and create a good atmosphere for sharing and exchange."],
    experience_3_7_1: "Why I left",
    experience_3_7: "The company went bankrupt because of the reshuffle in the live streaming industry.",
    experience_4_1: "Coolpad ",
    experience_4_2: "Sep 2016 → Jun 2017",
    experience_4_3: "Title：UI designer",
    experience_4_4_1: "Background",
    experience_4_4: "In the former company, our team was invited to join Coolpad and was responsible for the Coolpad online mall project.",
    experience_4_5: "Responsibilities",
    experience_4_6: ["Responsible for the UX and UI design of the desktop and mobile of the online mall.",
    "Build unified product design specification, formulate and maintain component library.",
    "Participate in product discussions and work with product managers to propose suggestions and solutions from the perspective of visual design and user experience.",
    "Guide the functional design of the product, participate in the planning and business combing process of the product, and decompose and summarize the interactive interface requirements of the product."],
    experience_4_7: "Projects",
    experience_4_8: ["Coolpad Mall for Desktop",
    "Coolpad Mall for Mobile",
    "CUI component library for Desktop",
    "CUI component library for Mobile"],
    experience_4_8_1: ["/portfolio/coolpadmallpc",
    "/portfolio/coolpadmallmobile",
    "/portfolio/coolpadpcui",
    "/portfolio/coolpadmobileui"],
    experience_4_9_1: "Why I left",
    experience_4_9: "Because of the Letv case, the company canceled the mobile phone business.",
    experience_5_1: "Dmall",
    experience_5_2: "Jul 2015 → Aug 2016",
    experience_5_3: "Title：UI designer",
    experience_5_4: "Website: ",
    experience_5_5: "Responsibilities",
    experience_5_6: ["Responsible for the overall design of SaaS products, including UX and UI design of web and app.",
    "Analyze business requirements and complete interface information architecture, process design and prototype design.",
    "Cooperate with engineers to track product realization effect and ensure design quality."],
    experience_5_7: "Projects",
    experience_5_8: ["Activity management system",
    "Cloud POS"],
    experience_5_8_1: ["/portfolio/dmallams",
    "/portfolio/dmallpos"],
    experience_5_9_1: "Why I left",
    experience_5_9: "Our team was invited to join Coolpad.",
    experience_6_1: "PPTV",
    experience_6_2: "Jun 2013 → May 2015",
    experience_6_3: "Title: Visual designer",
    experience_6_4: "Website: ",
    experience_6_5: "Responsibilities",
    experience_6_6: ["Responsible for the visual design of daily operational activities of the company's game platform, including activity page design, H5 design, banner design, flash animation design, etc.",
    "Participate in the team's marketing design discussion, track the product design effect & data, put forward the design optimization scheme, and help the team achieve the sales goals."],
    experience_6_7: "Projects",
    experience_6_8: ["Platform game publicity materials, brand advertising online material design.",
    "Design of active pages of perfect world dota2, hero League and other games."],
    // 技能和工具
    skills_tools_title: "Skills and tools",
    skills_1: "Product design",
    skills_2: "Front-end development",
    skills_3: "Interaction design",
    skills_4: "UI design",
    skills_5: "Animation Design",
    skills_1_1: "Product planning, business design, functionality design and Implementation",
    skills_3_1: "Interaction design document, interaction prototype",
    skills_4_1: "High availability component design, high fidelity user interface design, high-quality development collaboration",
    skills_5_1: "Motion design in UI, vector animation, video animation effects, video editing",
    // 项目集
    portfolio_title: "Projects",
    portfolio_tab_1: "All ",
    portfolio_tab_2: "Business-side",
    portfolio_tab_3: "Client-side",
    // 所思
    thoughts_title: "Thoughts",
    thoughts_1: "Do objective design, follow the design based on functionality and supplemented by formality, do not over design, balance the value of design in all dimensions in business, and be closer to business and technology in the Internet field, so as to maximize the design benefit ratio as far as possible.",
    thoughts_2: "Many people crowded into the internet industry because of its explosive growth, but someone does not really love and know how to make products, and this brought a lot of unqualified products. We should think about why we throw ourselves into it. If we just want to take a free ride in the internet industry for an aimless journey, this journey is doomed to see no scenery.",
    thoughts_3: "Technology and humanity are all indispensable in modern products. Technology is the method and the driving force, humanity is the purpose, technology is for better realization, and the significance of humanity is people-oriented.",
    thoughts_4: "Design thinking does not narrowly mean creativity, but a kind of well-intentioned service behavior. In terms of behavior, it is specifically manifested as a recursive procedure that cycles in induction and better rebuilds.",
    thoughts_5: "Internet designers do not understand programming, just like architectural designers do not understand engineering technology, which will make the seemingly cool drawings exist only as concepts and lack enforceability.",
    // hints
    hint_1: "Enable zoom with mouse wheel",
    hint_2: "Drag the image to reposition",
    hint_3: "Show the next image via the bottom toolbar",
    hint_4: "Pinch to zoom with two fingers",
    hint_5: "Close",
    hint_6: "Don't remind again",
    // 作品
    case_1: "LC Library",
    case_1_1: "LeetCode problem setting system",
    case_2: "LC Design Language System",
    case_2_1: "A set of general design solutions customized for a unified platform product experience",
    case_3: "Lingkou enterprise assessment tool",
    case_3_1: "A programming assessment tool for enterprise users",
    case_4: "AMS for Dmall",
    case_4_1: "A management system for quickly creating and running  e-commerce online activities",
    case_5: "Cloud POS for Dmall",
    case_5_1: "A cloud POS for supermarket which blends the mobile app account system",
    case_6: "Coolpad CUI for PC",
    case_6_1: "A web UI component library for desktop",
    case_7: "Coolpad CUI for Mobile",
    case_7_1: "A web UI component library for mobile",
    case_8: "Coolpad Mall for Desktop",
    case_8_1: "Coolpad online mall for desktop",
    case_9: "Coolpad Mall for Mobile",
    case_9_1: "Coolpad online mall for mobile",
    case_10: "Lisa Color",
    case_10_1: "A tool that makes building color system easier and faster",
    case_11: "Simple Code Editor",
    case_11_1: "A simple code editor for Vue.js, which is light, easy-to-use, and supported customizing themes",
    case_12: "Seeasas",
    case_12_1: "A website for CSS learning with friendly interaction",
    case_13: "3D Personal Website",
    case_13_1: "A classic website developed with jQuery and CSS3 animation",
    case_14: "Vue Resizer",
    case_14_1: "A series of the resizer components for Vue.js",
    case_15: "Chaier Studio",
    case_15_1: "This is my studio website which is used to introduce our business and thoughts",
    case_16: "My Personal Website",
    case_16_1: "A responsive website that show a collection of my projects",
}