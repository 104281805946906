<template>
  <svg
    fill="currentColor"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 40 40"
  >
    <path d="M29,6 C31.7614237,6 34,8.23857625 34,11 L34,29 C34,31.7614237 31.7614237,34 29,34 L11,34 C8.23857625,34 6,31.7614237 6,29 L6,11 C6,8.23857625 8.23857625,6 11,6 L29,6 Z M29,9 L11,9 C9.9456382,9 9.08183488,9.81587779 9.00548574,10.8507377 L9,11 L9,29 C9,30.0543618 9.81587779,30.9181651 10.8507377,30.9945143 L11,31 L29,31 C30.0543618,31 30.9181651,30.1841222 30.9945143,29.1492623 L31,29 L31,11 C31,9.9456382 30.1841222,9.08183488 29.1492623,9.00548574 L29,9 Z M24.8054487,22.0161867 C24.9234472,22.1095195 25.0305377,22.2158687 25.1246879,22.333216 L27.8654049,25.7492017 C28.2110237,26.1799755 28.141992,26.8093661 27.7112182,27.1549849 C27.5337277,27.2973893 27.3129763,27.375 27.0854199,27.375 L14.2755774,27.375 C13.7232927,27.375 13.2755774,26.9272847 13.2755774,26.375 C13.2755774,26.1097835 13.3809342,25.8554296 13.5684706,25.6678932 L16.0058985,23.2304653 C16.7774034,22.4589605 18.0269459,22.4547202 18.803669,23.2209713 L19.4129031,23.8219914 C19.8060688,24.2098563 20.4392192,24.2055591 20.827084,23.8123934 C20.8527013,23.786426 20.8768786,23.7590764 20.8995075,23.7304672 L21.9960921,22.3440818 C22.6813287,21.4777537 23.9391206,21.33095 24.8054487,22.0161867 Z M14.5,12 C15.8807119,12 17,13.1192881 17,14.5 C17,15.8807119 15.8807119,17 14.5,17 C13.1192881,17 12,15.8807119 12,14.5 C12,13.1192881 13.1192881,12 14.5,12 Z"></path>
  </svg>
</template>
<script>
export default {
  name: "PhotoIcon",
};
</script>