<template>
  <div class="article">
    <h1>Coolpad CUI for Mobile</h1>
    <i>酷派移动端 Web UI 组件库</i>
      <p>所属公司：Coolpad 酷派</p>
      <p>负责环节：</p>
      <ol>
        <li>UI Design</li>
        <li>CSS Coding</li>
      </ol>
      <p>设计成员：1</p>
      <p>
        项目描述：为 Coolpad 移动线上商场而定制的一套 UI 组件库
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>Sketch</li>
      <li>Sublime Text</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/sketch.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/sublime.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>输出内容</h2>
    <ol>
      <li>GUI 设计文档</li>
      <li>HTML + CSS 静态组件样式</li>
    </ol>
    <h2>组件库预览</h2>
    <a href="https://cui-mobile.vicuxd.com/" target="_blank">点击预览 https://cui-mobile.vicuxd.com/</a>
    <br>
    <br>
    <a href="https://cui-mobile.vicuxd.com/document/COUIdDocument.html" target="_blank">查看使用文档 https://cui-mobile.vicuxd.com/document/COUIdDocument.html</a>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/coolpad_mobile_ui/1.jpg`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>