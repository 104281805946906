import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createStore } from 'vuex'
import VueViewer from "v-viewer";
import "./css/viewer.css";
// 
const store = createStore({
    state() { // 设置全局数据
        return {
            language: 'EN',
            isShowHint: false,
            isRenderHint: true
        }
    },
    mutations: {  // 设置一个全局函数
        switchLanguage(state) {
            state.language = state.language == 'EN' ? 'CN' : 'EN'
        },
        autoSwitchLanguage(state, lang) {
            if (lang == 'en') {
                state.language = 'EN'
            } else if (lang == 'zh') {
                state.language = 'CN'
            }
        },
        openHint(state) {
            state.isShowHint = true
        },
        closeHint(state) {
            state.isShowHint = false
        },
        neverShowHint(state) {
            state.isRenderHint = false
        }
    },
    getters: { // 抛出一个全局数据
        currentLang: (state) => {
            return state.language
        },
        isShowHint: (state) => {
            return state.isShowHint
        },
        isRenderHint: (state) => {
            return state.isRenderHint
        }
    }
})
// 
const app = createApp(App)
app.use(VueViewer, {
    defaultOptions: {
        toggleOnDblclick: false,
        slideOnTouch: false,
        zIndex: 3,
        shown() { // 当图片展现时展示提示
            store.commit('openHint')
        },
        hide() { // 当图片关闭时隐藏提示
            store.commit('closeHint')
        }
    }
})
app.use(router).mount('#app')
app.use(store)
