export default {
    login_title: "Vic's Portfolio",
    login_contact: "联系我",
    login_hints: '如果大厂背景和傲人履历是您的必要条件，请勿继续访问。如果您更在意个人能力、品行、责任心和务实性，那么我将很高兴向您介绍我自己。',
    login_button: "前往访问",
    login_placeholder: '请输入密码',
    login_contact_info: `<span>👋 你好，我是 Vic，</span>一名产品设计师，这是我的个人简历和项目集，如果您对我所作的感兴趣，请联系我:
    <a href="mailto:hlgtsja@163.com">hlgtsja@163.com</a>`,
    login_password_hint: "无效密码",
    aboutMeTitle: '关于我',
    aboutMeContent: `我是一名软件产品设计师及 Web 前端开发者，8 年互联网从业经验，专注软件产品设计，包括：业务设计、功能设计、交互设计、界面设计。同时热爱前端开发，有超过 5 年的 Web 前端自学和开发经历。
    <br>
    <br>工作中我热衷于业务、技术与体验交融的产品设计，生产中擅于搭建并运用 UI 设计系统，熟悉产品生产流程中的各个环节，并对何如高效合作协同有一定的见解，特别是技术与设计的协同，有较强的产品 sense 和同理心，擅于洞悉和观察新鲜事物，如果您需要一名擅于协同、了解技术和业务、并对用户体验有高要求的产品设计师，那我将很高兴向您介绍我自己，谢谢。`,
    aboutMeTitle_1: "我所做的",
    aboutMeCard_1: "产品设计",
    aboutMeCard_1_content: "熟悉软件产品设计、开发全流程，具备较强的产品 owner 意识、责任心、执行落地和业务能力，具有 C 端和 B 端的产品设计经验。",
    aboutMeCard_2: "Web 前端开发",
    aboutMeCard_2_content: "从传统的 JS, jQuery 操作 DOM 到 SPA，具备无框架页面开发和 Vue.js 应用开发能力，对组件化思维和 CSS 自适应页面布局有较深入的见解。",
    aboutMeCard_3: "交互设计",
    aboutMeCard_3_content: "熟练掌握以用户为中心的设计原则，熟悉桌面端、手持设备端等各平台终端的人机交互准则和规范，有极强的用户同理心，及有效表达。",
    aboutMeCard_4: "用户界面设计",
    aboutMeCard_4_content: "熟练掌握组件化设计、擅长在生产中搭建运用设计系统，具备一定的视觉和动画表现能力，具有桌面 Web、移动 Web、移动 App 等界面设计经验。",
    aboutMeTitle_2: "服务品牌",
    nav: ["关于我", "简历", "项目集", "所思"],
    email: "邮箱",
    phone: "电话",
    birthday: "出生日期",
    location: "所在地",
    phone_content: "15000532387",
    birthday_content: "1990 年 10 月 27 日",
    location_content: "中国 上海",
    name: "王卫军",
    title: "软件产品设计",
    // 简历
    resume_title: "简历",
    resume_title_1: "教育经历",
    education_1: "上海商学院",
    education_1_date: "2009 / 09 → 2013 / 06",
    education_1_des: "专业：新媒体艺术设计",
    education_1_edu: "学历：统招本科",
    education_1_degree: "学位：学士",
    experience_title: "工作经历",
    experience_1_1: "柴二",
    experience_1_2: "2020 / 10 → 至今",
    experience_1_3: "职位：工作室 owner",
    experience_1_4: "网站：",
    experience_1_5_1: "背景简介",
    experience_1_5: "个人成立的以软件产品设计、开发、绘本设计、平面设计项目为主的工作室。",
    experience_1_6_1: "工作内容",
    experience_1_6: "作为工作室的经营者，日常负责各个项目的对接执行，作为生产担当，负责包括产品设计与开发在内的各类工作，项目包含网页设计、开发、app 设计、KV 设计等，同时与插画师合作协同生产绘本与各类版式设计。",
    experience_2_1: "LeetCode",
    experience_2_2: "2018 / 05 → 2020 / 01",
    experience_2_3: "职位：产品设计总监",
    experience_2_4: "网站：",
    experience_2_5_1: "背景简介",
    experience_2_5: "作为 LeetCode 力扣在国内公司的创始团队成员，从 0 到 1 建立了公司的产品设计体系及产品设计团队，负责公司 C 端和 B 端各个业务线的产品设计工作包括部分海外业务，参与规划公司的产品策略及业务方向，与核心技术成员共同设计前沿技术为导向的功能模块，两年内公司实现盈亏平衡。",
    experience_2_6: "工作内容",
    experience_2_7: ["负责产品整体设计规划， 对全流程进行思考、拆解及策略优化。",
    "建立统一的产品设计规范 design system、制定与维护 UI 组件库。",
    "负责公司 B 端和 C 端产品的 UX 和 UI 设计。",
    "制定产品中长期规划，通过数据分析、用户反馈收集等手段发现用户需求和产品体验优化点输出有价值的策略优化方案或建议并推动落地。",
    "跟进项目的进度，有效协同研发、运营、设计等职能团队，推动相关产品功能快速迭代，实现产品增长。",
    "结合行业理解，构建用户场景，推进用户体验的持续优化，提升用户价值。"],
    experience_2_8: "主要项目",
    experience_2_9: ["LeetCode 主站模拟面试功能模块",
    "企业测评系统 Lingkou 1.0",
    "LDS 设计系统",
    "LC Library 力扣题库系统"],
    experience_2_9_1: ["https://leetcode.cn/company/",
    "/portfolio/lingkou",
    "/portfolio/lds",
    "/portfolio/library"],
    experience_2_10_1: "离职原因",
    experience_2_10: "家庭原因，离职并自营个人工作室 - 柴二",
    experience_3_1: "全民直播",
    experience_3_2: "2017 / 10 → 2018 / 05",
    experience_3_3: "职位：交互设计经理",
    experience_3_4_1: "背景简介",
    experience_3_4: "正值直播行业风口，受同事邀约参与直播行业的创新营销项目，为直播行业寻求新的具有变革意义的营销模式，参与了最早的直播电商雏形。",
    experience_3_5: "工作内容",
    experience_3_6: ["负责多条业务线的产品及运营活动设计包括直播和游戏业务，把控整体质量及效率，基于对现状的分析和评估，挖掘设计机会。","基于公司战略，制定与之匹配的设计及团队管理策略， 带领团队深耕平台营销业务，主动探索解决方案，助力业务目标达成。","带领团队从实践中提炼设计方法、沉淀优秀案例，打造良好的分享交流氛围。"],
    experience_3_7_1: "离职原因",
    experience_3_7: "直播行业洗牌，公司倒闭",
    experience_4_1: "酷派 Coolpad ",
    experience_4_2: "2016 / 09 → 2017 / 06",
    experience_4_3: "职位：UI 设计师",
    experience_4_4_1: "背景简介",
    experience_4_4: "在上家公司，我所在团队被邀加入酷派，并负责酷派在线商城项目。",
    experience_4_5: "工作内容",
    experience_4_6: ["负责线上商城桌面端与移动端的 UX 和 UI 设计。",
    "建立统一的产品设计规范、制定与维护组件库。",
    "参与产品讨论，与产品经理协同，从视觉设计和用户体验的角度提出建议和解决方案。",
    "引导产品的功能设计，参与产品的规划构思和业务梳理过程，分解归纳出产品的交互界面需求。"],
    experience_4_7: "主要项目",
    experience_4_8: ["酷派商城 for Desktop",
    "酷派商城 for Mobile",
    "CUI 组件库 for Desktop",
    "CUI 组件库 for Mobile"],
    experience_4_8_1: ["/portfolio/coolpadmallpc",
    "/portfolio/coolpadmallmobile",
    "/portfolio/coolpadpcui",
    "/portfolio/coolpadmobileui"],
    experience_4_9_1: "离职原因",
    experience_4_9: "因乐视事件，公司取消手机业务线",
    experience_5_1: "多点生活 Dmall",
    experience_5_2: "2015 / 07 → 2016 / 08",
    experience_5_3: "职位：UI 设计师",
    experience_5_4: "网站：",
    experience_5_5: "工作内容",
    experience_5_6: ["负责 SaaS 工具类产品的整体设计工作，包括 Web、App 多端产品的 UX 和 UI 设计。",
    "分析业务需求完成界面信息架构、流程设计和原型设计。",
    "与工程师协作，跟踪产品实现效果，保证设计质量。"],
    experience_5_7: "主要项目",
    experience_5_8: ["AMS 线上商城活动管理系统",
    "商超云 POS"],
    experience_5_8_1: ["/portfolio/dmallams",
    "/portfolio/dmallpos"],
    experience_5_9_1: "离职原因",
    experience_5_9: "随团队加入酷派集团",
    experience_6_1: "PPTV 聚力传媒",
    experience_6_2: "2013 / 06 → 2015 / 05",
    experience_6_3: "职位：视觉设计师",
    experience_6_4: "网站：",
    experience_6_5: "工作内容",
    experience_6_6: ["负责公司游戏平台日常运营活动视觉设计，包括专题设计、H5设计、Banner 设计、Flash 动画设计等。",
    "参与团队营销设计讨论，跟踪产品设计效果&数据，提出设计优化方案并帮助团队完成销售目标。"],
    experience_6_7: "主要项目",
    experience_6_8: ["平台各游戏宣传物料，品牌广告线上物料设计",
    "完美世界 Dota2、英雄联盟等游戏专题页设计"],
    // 技能和工具
    skills_tools_title: "技能和工具",
    skills_1: "产品设计",
    skills_2: "前端开发",
    skills_3: "交互设计",
    skills_4: "用户界面设计",
    skills_5: "动效设计",
    skills_1_1: "产品规划, 业务设计, 功能设计, 执行落地",
    skills_3_1: "交互设计文档, 可交互原型",
    skills_4_1: "高可用组件设计, 高保真界面设计, 高质量开发协同",
    skills_5_1: "界面动效设计, 矢量动画, 视频动画特效, 视频剪辑",
    // 项目集
    portfolio_title: "项目集",
    portfolio_tab_1: "全部",
    portfolio_tab_2: "B 端项目",
    portfolio_tab_3: "C 端项目",
    // 所思
    thoughts_title: "所思",
    thoughts_1: "做客观的设计，遵循以功能性为主，形式性为辅的设计，不过度设计，在商业场景中须平衡设计在各个维度中的价值，在互联网领域设计更应贴近业务和技术，尽可能做到设计效益比最大化。",
    thoughts_2: "互联网行业的爆发式增长，催生出许多并不真正热爱和懂得做产品的人，使得不合格的产品铺天盖地，我们要思考自己为何要投身于此，如果只是为了搭上互联网的便车进行漫无目的的旅途，那这段旅途注定看不到风景。",
    thoughts_3: "科技和人文在现代产品中都是不可或缺的，科技是方法是驱动力，人文是目的，科技是为了更好的实现，人文的意义是以人为本，缺一不可。",
    thoughts_4: "所谓设计思维，非狭义的表示创造性，而是一种善意的服务行为，在行为上具体表现为：归纳总结、优化组合然后再归纳总结、优化组合... 的递归过程。",
    thoughts_5: "互联网设计师不了解编程，就像是建筑设计师不懂工程技术，这会让看似酷炫的图纸只能作为概念存在，缺乏可执行度。",
    // 提示
    hint_1: "使用鼠标滚轮缩放图片",
    hint_2: "点击图片进行拖拽",
    hint_3: "使用底部工具栏切换下一图",
    hint_4: "两指拖拽缩放图片",
    hint_5: "关闭",
    hint_6: "不再提示",
    // 作品
    case_1: "LC Library",
    case_1_1: "力扣题库系统",
    case_2: "LC Design Language System",
    case_2_1: "为统一平台产品体验而定制的一整套通用设计方案",
    case_3: "Lingkou 企业测评工具",
    case_3_1: "一款面向企业端用户的编程评测工具",
    case_4: "多点 - 活动管理系统",
    case_4_1: "一款快速创建、上线电商活动的管理系统",
    case_5: "多点 - 云 POS",
    case_5_1: "结合 app 账户体系融合线上促销活动的商超 POS",
    case_6: "Coolpad CUI for PC",
    case_6_1: "酷派桌面端 Web UI 组件库",
    case_7: "Coolpad CUI for Mobile",
    case_7_1: "酷派移动端 Web UI 组件库",
    case_8: "Coolpad Mall for Desktop",
    case_8_1: "酷派桌面端线上商城",
    case_9: "Coolpad Mall for Mobile",
    case_9_1: "酷派移动端线上商城",
    case_10: "Lisa Color",
    case_10_1: "色彩系统快速构建工具",
    case_11: "Simple Code Editor",
    case_11_1: "简易 Web 代码编辑器 for Vue.js",
    case_12: "Seeasas",
    case_12_1: "交互式 CSS 学习站点",
    case_13: "3D Personal Website",
    case_13_1: "一个简易的 CMS 个人展示网站",
    case_14: "Vue Resizer",
    case_14_1: "基于 Vue.js 的一系列拖拽缩放组件",
    case_15: "柴二工作室",
    case_15_1: "人文、科技领域创作者",
    case_16: "个人网站",
    case_16_1: "展示个人项目集合的响应式网站",
}