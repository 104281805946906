<template>
  <div class="resume">
    <h1>{{ content.resume_title }}</h1>
    <div class="short_line"></div>
    <!-- Education -->
    <section>
      <div class="resume_title">
        <IconBox>
          <BookOpenIcon />
        </IconBox>
        <h2>{{ content.resume_title_1 }}</h2>
      </div>
      <div class="item">
        <h4>{{ content.education_1 }}</h4>
        <div>{{ content.education_1_date }}</div>
        <div class="item_grey">
          {{ content.education_1_des }}
        </div>
        <div class="item_grey">
          {{ content.education_1_edu }}
        </div>
        <div class="item_grey">
          {{ content.education_1_degree }}
        </div>
      </div>
    </section>
    <!-- Experience -->
    <section>
      <div class="resume_title">
        <IconBox>
          <BriefcaseIcon />
        </IconBox>
        <h2>{{ content.experience_title }}</h2>
      </div>
      <!-- 柴二 -->
      <div class="item">
        <h4>{{ content.experience_1_1 }}</h4>
        <div>{{ content.experience_1_2 }}</div>
        <div class="item_grey">
          {{ content.experience_1_3 }}
        </div>
        <div class="item_grey">
          {{ content.experience_1_4
          }}<a href="https://chaier.art" target="_blank">chaier.art</a>
        </div>
        <div class="item_grey dark">
          {{ content.experience_1_5_1 }}
        </div>
        <div class="item_grey">
          {{ content.experience_1_5 }}
        </div>
        <div class="item_grey dark">
          {{ content.experience_1_6_1 }}
        </div>
        <div class="item_grey">
          {{ content.experience_1_6 }}
        </div>
      </div>
      <!-- LeetCode -->
      <div class="item">
        <h4>{{ content.experience_2_1 }}</h4>
        <div>{{ content.experience_2_2 }}</div>
        <div class="item_grey">
          {{ content.experience_2_3 }}
        </div>
        <div class="item_grey">
          {{ content.experience_2_4
          }}<a href="https://leetcode.com" target="_blank">leetcode.com</a>
        </div>
        <div class="item_grey dark">
          {{ content.experience_2_5_1 }}
        </div>
        <div class="item_grey">
          {{ content.experience_2_5 }}
        </div>
        <div class="item_grey dark">
          {{ content.experience_2_6 }}
        </div>
        <ul class="item_grey">
          <li v-for="(item, index) in content.experience_2_7" :key="index">{{ item }}</li>
        </ul>
        <div class="item_grey dark">
          {{ content.experience_2_8 }}
        </div>
        <ul class="item_grey">
          <li>
            <a :href="content.experience_2_9_1[0]" target="_blank">{{content.experience_2_9[0]}}</a>
          </li>
          <li>
            <router-link :to="content.experience_2_9_1[1]">{{ content.experience_2_9[1] }}</router-link>
          </li>
          <li>
            <router-link :to="content.experience_2_9_1[2]">{{ content.experience_2_9[2] }}</router-link>
          </li>
          <li>
            <router-link :to="content.experience_2_9_1[3]">{{ content.experience_2_9[3] }}</router-link>
          </li>
        </ul>
        <div class="item_grey dark">
          {{ content.experience_2_10_1 }}
        </div>
        <div class="item_grey">
          {{ content.experience_2_10 }}
        </div>
      </div>
      <!-- 全民直播 -->
      <div class="item">
        <h4>{{ content.experience_3_1 }}</h4>
        <div>{{ content.experience_3_2 }}</div>
        <div class="item_grey">
          {{ content.experience_3_3 }}
        </div>
        <div class="item_grey dark">
          {{ content.experience_3_4_1 }}
        </div>
        <div class="item_grey">
          {{ content.experience_3_4 }}
        </div>
        <div class="item_grey dark">
          {{ content.experience_3_5 }}
        </div>
        <ul class="item_grey">
          <li v-for="(item, index) in content.experience_3_6" :key="index">{{ item }}</li>
        </ul>
        <div class="item_grey dark">
          {{ content.experience_3_7_1 }}
        </div>
        <div class="item_grey">
          {{ content.experience_3_7 }}
        </div>
      </div>
      <!-- 酷派 Coolpad -->
      <div class="item">
        <h4>{{ content.experience_4_1 }}</h4>
        <div>{{ content.experience_4_2 }}</div>
        <div class="item_grey">
          {{ content.experience_4_3 }}
        </div>
        <div class="item_grey dark">
          {{ content.experience_4_4_1 }}
        </div>
        <div class="item_grey">
          {{ content.experience_4_4 }}
        </div>
        <div class="item_grey dark">
          {{ content.experience_4_5 }}
        </div>
        <ul class="item_grey">
          <li v-for="(item, index) in content.experience_4_6" :key="index">{{ item }}</li>
        </ul>
        <div class="item_grey dark">
          {{ content.experience_4_7 }}
        </div>
        <ul class="item_grey">
          <li v-for="(item, index) in content.experience_4_8" :key="index">
            <router-link :to="content.experience_4_8_1[index]">{{ item }}</router-link>
          </li>
        </ul>
        <div class="item_grey dark">
          {{ content.experience_4_9_1 }}
        </div>
        <div class="item_grey">
          {{ content.experience_4_9 }}
        </div>
      </div>
      <!-- 多点 -->
      <div class="item">
        <h4>{{ content.experience_5_1 }}</h4>
        <div>{{ content.experience_5_2 }}</div>
        <div class="item_grey">
          {{ content.experience_5_3 }}
        </div>
        <div class="item_grey">
          {{ content.experience_5_4
          }}<a href="https://dmall.com" target="_blank">dmall.com</a>
        </div>
        <div class="item_grey dark">
          {{ content.experience_5_5 }}
        </div>
        <ul class="item_grey">
          <li v-for="(item, index) in content.experience_5_6" :key="index">{{ item }}</li>
        </ul>
        <div class="item_grey dark">
          {{ content.experience_5_7 }}
        </div>
        <ul class="item_grey">
          <li v-for="(item, index) in content.experience_5_8" :key="index">
            <router-link :to="content.experience_5_8_1[index]">{{ item }}</router-link>
          </li>
        </ul>
        <div class="item_grey dark">
          {{ content.experience_5_9_1 }}
        </div>
        <div class="item_grey">
          {{ content.experience_5_9 }}
        </div>
      </div>
      <!-- pptv -->
      <div class="item">
        <h4>{{ content.experience_6_1 }}</h4>
        <div>{{ content.experience_6_2 }}</div>
        <div class="item_grey">
          {{ content.experience_6_3 }}
        </div>
        <div class="item_grey">
          {{ content.experience_6_4
          }}<a href="https://pptv.com" target="_blank">pptv.com</a>
        </div>
        <div class="item_grey dark">
          {{ content.experience_6_5 }}
        </div>
        <ul class="item_grey">
          <li v-for="(item, index) in content.experience_6_6" :key="index">{{ item }}</li>
        </ul>
        <div class="item_grey dark">
          {{ content.experience_6_7 }}
        </div>
        <ul class="item_grey">
          <li v-for="(item, index) in content.experience_6_8" :key="index">{{ item }}</li>
        </ul>
      </div>
    </section>
    <!-- Skills and Tools -->
    <section>
      <h2>{{content.skills_tools_title}}</h2>
      <section class="skills gradient_bg">
        <Progress :name="content.skills_1" :perc="80" />
        <Progress :name="content.skills_3" :perc="90" />
        <Progress :name="content.skills_4" :perc="80" />
        <Progress :name="content.skills_2" :perc="60" />
        <Progress :name="content.skills_5" :perc="40" />
      </section>
      <div class="section">
        <div class="item">
          <h4>{{content.skills_1}}</h4>
          <div>Axure<i />ProcessOn<i />Google Docs<i />Teambition</div>
          <div class="item_grey">
            {{content.skills_1_1}}
          </div>
        </div>
        <div class="item">
          <h4>{{content.skills_2}}</h4>
          <div>VS Code<i />Webpack<i />Git</div>
          <div class="item_grey">Vue.js<i />Native (HTML + CSS + JS)</div>
        </div>
        <div class="item">
          <h4>{{content.skills_3}}</h4>
          <div>Figma<i />Sketch<i />Axure</div>
          <div class="item_grey">{{content.skills_3_1}}</div>
        </div>
        <div class="item">
          <h4>{{content.skills_4}}</h4>
          <div>
            Figma<i />Sketch<i />Principle<i />Photoshop<i />Illustrator
          </div>
          <div class="item_grey">{{content.skills_4_1}}</div>
        </div>
        <div class="item">
          <h4>{{content.skills_5}}</h4>
          <div>Premiere<i />After Effects<i />EDIUS</div>
          <div class="item_grey">{{content.skills_5_1}}</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import dataEN from "../content/dataEN";
import dataCN from "../content/dataCN";
import { BookOpenIcon, BriefcaseIcon } from "@zhuowenli/vue-feather-icons";
import IconBox from "../components/IconBox.vue";
import Progress from "../components/Progress.vue";
export default {
  name: "About",
  components: {
    IconBox,
    BookOpenIcon,
    BriefcaseIcon,
    Progress,
  },
  data() {
    return {
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  computed: {
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
  },
};
</script>
<style lang="scss">
.short_line {
  margin: 20px 0;
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background: var(--main_5);
}
.resume {
  h1 {
    color: var(--grey_9);
  }
  h2 {
    color: var(--grey_9);
  }
  p {
    font-weight: 300;
    color: 14px;
    color: var(--grey_7);
  }
  i:before {
    content: " , ";
    color: var(--grey_7);
  }
}
.resume_title {
  position: relative;
  display: flex;
  padding-top: 20px;
  margin-bottom: 36px;
  h2 {
    line-height: 48px;
    margin-left: 20px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 24px;
    top: 38px;
    width: 1px;
    height: 60px;
    background: var(--grey_4);
    z-index: 0;
  }
}
.item {
  position: relative;
  padding: 0 0 50px 45px;
  margin: 0 0 0 24px;
  border-left: 1px solid var(--grey_4);
  &:last-child {
    border-left: none;
  }
  &:before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    left: -8px;
    top: -9px;
    width: 16px;
    height: 16px;
    background: var(--main_5);
    border-radius: 50%;
    border: 4px solid var(--grey_4);
  }
  h4 {
    line-height: 24px;
    top: -12px;
    position: relative;
    color: var(--grey_8);
  }
  h4 + div {
    margin: 0 0 8px 0;
    font-size: 15px;
    color: var(--main_5);
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    line-height: 1.5;
    font-size: 16px;
    color: var(--grey_7);
    &::marker {
      color: var(--grey_4);
    }
  }
}
.dark.item_grey {
  color: var(--grey_6);
}
.item_grey {
  font-size: 16px;
  color: var(--grey_7);
  line-height: 1.5;
  a {
    transition: color 0.2s;
    color: var(--grey_7);
    text-decoration: underline;
    &:hover {
      color: var(--main_5);
    }
  }
  & + .item_grey {
    margin-top: 8px;
  }
}
.skills {
  margin-top: 32px;
  margin-bottom: 40px;
  padding: 24px 36px;
  > div + div {
    margin-top: 20px;
  }
}
</style>
