<template>
  <div
    class="hint"
    v-show="this.$store.state.isShowHint"
    v-if="this.$store.state.isRenderHint"
  >
    <div class="hint_body">
      <div class="hint_container" v-show="!isMobile">
        <!-- <div class="hint_line">
          <PhotoIcon />
          <p>{{ content.hint_3 }}</p>
        </div> -->
        <div class="hint_line">
          <MouseWheelIcon />
          <p>{{ content.hint_1 }}</p>
        </div>
        <div class="hint_line">
          <MoustLeftButtonIcon />
          <p>{{ content.hint_2 }}</p>
        </div>
      </div>
      <div class="hint_container" v-show="isMobile">
        <div class="hint_line">
          <PhotoIcon />
          <p>{{ content.hint_3 }}</p>
        </div>
        <div class="hint_line">
          <GestureIcon />
          <p>{{ content.hint_4 }}</p>
        </div>
      </div>
    </div>
    <div class="hint_footer">
      <button class="border" @click="closeHint">{{ content.hint_5 }}</button>
      <button class="solid" @click="neverShowHint">{{ content.hint_6 }}</button>
    </div>
  </div>
</template>

<script>
import GestureIcon from "./icon/GestureIcon.vue";
import MouseWheelIcon from "./icon/MouseWheelIcon.vue";
import MoustLeftButtonIcon from "./icon/MoustLeftButtonIcon.vue";
import PhotoIcon from "./icon/PhotoIcon.vue";
import dataEN from "../content/dataEN";
import dataCN from "../content/dataCN";
export default {
  name: "Hint",
  components: {
    GestureIcon,
    MouseWheelIcon,
    MoustLeftButtonIcon,
    PhotoIcon,
  },
  data() {
    return {
      isMobile: false,
      contentEN: dataEN,
      contentCN: dataCN,
    };
  },
  methods: {
    detectMob() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    closeHint() {
      this.$store.commit("closeHint");
    },
    neverShowHint() {
      this.$store.commit("neverShowHint");
    },
  },
  computed: {
    content() {
      return this.$store.state.language == "EN"
        ? this.contentEN
        : this.contentCN;
    },
  },
  mounted() {
    this.isMobile = this.detectMob();
  },
};
</script>

<style lang="scss">
.hint {
  position: fixed;
  max-width: 270px;
  min-width: 250px;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  box-shadow: inset 0 0 0 1px var(--grey_4), 0 2px 20px rgba(0, 0, 0, 0.6);
  z-index: 7;
  border-radius: 16px;
  background: rgba(30, 30, 30, 0.9);
  color: var(--grey_9);
  svg {
    width: 24px;
    flex-shrink: 0;
  }
}
.hint_body {
  text-align: center;
  padding: 20px 20px 16px 20px;
}
.hint_container {
  display: inline-block;
}
.hint_line {
  display: flex;
  align-items: flex-start;
  & + .hint_line {
    margin-top: 12px;
  }
  p {
    text-align: left;
    margin-left: 8px;
    line-height: 24px;
    font-size: 13px;
  }
}
button.solid {
  height: 32px;
  line-height: 32px;
  width: auto;
  padding: 0 16px;
  border-radius: 17px;
  font-size: 13px;
  white-space: nowrap;
  background: var(--black);
  margin: 0;
  font-weight: normal;
}
button.border {
  transition: box-shadow 0.2s, color 0.2s;
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
  font-weight: normal;
  width: auto;
  padding: 0 16px;
  border-radius: 17px;
  font-size: 13px;
  background: none;
  box-shadow: inset 0 0 0 1px var(--grey_9);
  color: var(--grey_9);
  margin: 0;
  &:hover {
    box-shadow: inset 0 0 0 1px var(--main_5);
    color: var(--main_5);
    background: none;
  }
}
.hint_footer {
  display: flex;
  padding: 0 20px 20px 20px;
  justify-content: center;
  button + button {
    margin-left: 20px;
  }
}
</style>