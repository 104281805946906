<template>
  <div class="card">
    <div class="card_img">
      <div :class="{tag, link: isLink}">{{ tag }}</div>
      <a :href="link" v-if="isLink" target="_blank"></a>
      <router-link :to="link" v-if="!isLink"></router-link>
        <VLazyImage :src="img" :src-placeholder="require(`@/assets/loading.jpg`)" alt="" class="img_cover" />
    </div>
    <div class="card_title">
      <span>{{ title }}</span>
      <ImageIcon v-if="!isLink" />
      <ExternalLinkIcon v-if="isLink" />
    </div>
    <div :title="des" class="card_des">{{ des }}</div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
import {
  ImageIcon,
  ExternalLinkIcon
} from "@zhuowenli/vue-feather-icons";
export default {
  name: "CardNew",
  components: {
    VLazyImage,
    ImageIcon,
    ExternalLinkIcon,
  },
  props: {
    tag: {
      type: String,
      default: "UI",
    },
    link: {
      type: String,
      default: "#",
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Finance",
    },
    des: {
      type: String,
      default: "Web development",
    },
  }
};
</script>
<style lang="scss">
.card {
  transition: margin-top 0.2s;

  &:hover {
    margin-top: -6px;

    .img_cover {
      opacity: 0.9;
    }

    .card_title {
      color: var(--grey_10);
    }
  }
}

.card_img {
  cursor: pointer;
  overflow: hidden;
  border-radius: var(--radius_s);
  position: relative;

  >a {
    opacity: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: red;
    z-index: 1;
  }

  .img_cover {
    transition: opacity 0.2s;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.card_title {
  position: relative;
  transition: color 0.2s;
  color: var(--grey_7);
  font-size: 18px;
  margin: 12px 0 4px 0;
  overflow: hidden;

  >span {
    padding: 0 26px 0 4px;
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
  }

  >svg {
    opacity: .3;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.card_des {
  padding-left: 4px;
  color: var(--grey_6);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  font-size: 12px;
  border-radius: 12px 0 0 0;
  color: var(--grey_5);
  background: rgba(4, 218, 100, 0.8);
  padding: 6px 10px;
  user-select: none;
}
.tag.link {
  background: rgba(3, 158, 255, 0.8);
}
</style>