<template>
  <div class="thought gradient_bg">
    <div class="thought_mark">
      <div></div>
      <div></div>
    </div>
    <p>
      <slot></slot>
    </p>
  </div>
</template>
<script>
export default {
  name: "Thought",
  components: {},
};
</script>
<style lang="scss">
.thought {
  padding: 36px 32px;
  display: flex;
  > p {
    color: var(--grey_8);
    font-size: 16px;
    line-height: 1.8;
    font-weight: 300;
  }
}
.thought_mark {
  padding-top: 18px;
  display: inline-flex;
  margin-right: 30px;
  > div + div {
    margin-left: 5px;
  }
  > div {
    width: 20px;
    height: 20px;
    background: var(--main_5);
    border-radius: 30px;
    position: relative;
    transform: rotateZ(30deg);
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      display: block;
      height: 20px;
      width: 4px;
      background: var(--main_5);
      border-radius: 15px 0 0 0;
    }
  }
}
</style>