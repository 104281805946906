<template>
  <div class="article">
    <h1>Lisa Color</h1>
    <i>色彩系统快速构建工具</i>
      <p>该项目为个人项目</p>
      <p>负责环节：</p>
      <ol>
        <li>Product Design</li>
        <li>UX Design</li>
        <li>UI Design</li>
        <li>Development</li>
      </ol>
      <p>参与成员：1</p>
      <p>
        项目描述：Lisa Color 是一个为 Design System 快速创建 Color System 的线上工具，它具备自定义颜色梯度曲线、梯度数量、混色等功能，并且支持一键生成镜像主题，如 Light Theme 和 Dark Theme，该项目是一个单页应用，纯前端项目，使用自适应布局，适配桌面端、Pad 端和手机端。
      </p>
    <h2>生产工具</h2>
    <ul>
      <li>Sketch</li>
      <li>VS Code</li>
      <li>原生 JS + HTML + SCSS</li>
    </ul>
    <br />
    <VLazyImage :src="require(`@/assets/sketch.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <VLazyImage :src="require(`@/assets/vscode.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    <h2>项目地址</h2>
    <a href="https://lisa.vicuxd.com/landing.html" target="_blank">前往查看 https://lisa.vicuxd.com/landing.html</a>
    <viewer class="img">
      <VLazyImage :src="require(`@/assets/lisa_color/1.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/lisa_color/2.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
      <VLazyImage :src="require(`@/assets/lisa_color/3.png`)" :src-placeholder="require(`@/assets/loading.jpg`)" alt=""/>
    </viewer>

  </div>
</template>

<script>
import VLazyImage from "v-lazy-image"
export default {
  name: "Library",
  components: {
    VLazyImage
  },
};
</script>